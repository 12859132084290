import { $convertFromMarkdownString } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React, { useCallback } from 'react';
import {
	type Key as AriaKey,
	Button,
	ListBox,
	ListBoxItem,
	Popover,
	Select,
} from 'react-aria-components';
import styled from 'styled-components';

import { colors, effects, fonts } from '../../theme';
import UnstyledIconButton, {
	styles as iconButtonStyles,
} from '../../theme/components/icon-button';
import { TableIcon } from '../../theme/icons/document';
import { trackEvent, type ViewType } from '../../utils/analytics';

import { TRANSFORMERS } from './markdown-transformers';

import { isEditorEmpty } from '.';

const IconButton = styled(UnstyledIconButton)`
	border-radius: 8px;
`;
const TemplateListButton = styled(Button)`
	${iconButtonStyles}
	border-radius: 8px;
`;
const TemplateListPopover = styled(Popover)`
	min-width: var(--trigger-width);

	.react-aria-ListBox {
		${effects.shadow.hover};
		background: ${colors.layer.layer};
		border-radius: 8px;
		border: 1px solid ${colors.border.subtle};
		outline: none;
		padding: 6px 0;

		.react-aria-ListBoxItem {
			${fonts.paragraph.paragraph};
			color: ${colors.text.secondary};
			outline: none;
			padding: 6px 6px;

			&:hover,
			&:focus-visible {
				background: ${colors.layer.hover};
				color: ${colors.text.secondaryHover};
				cursor: pointer;
			}
		}
	}
`;

interface TemplateData {
	label: string;
	template: string;
}

interface Props {
	analyticsComponentIdentifier: string;
	analyticsViewType: ViewType;
	templates: TemplateData[];
}

export default function TemplatePlugin({
	analyticsComponentIdentifier,
	analyticsViewType,
	templates,
}: Props) {
	const [editor] = useLexicalComposerContext();

	const applyTemplate = useCallback(
		(template: string) => {
			editor.update(() =>
				$convertFromMarkdownString(template, TRANSFORMERS),
			);
			trackEvent(
				'Apply Note Template',
				analyticsComponentIdentifier,
				analyticsViewType,
			);
		},
		[analyticsComponentIdentifier, analyticsViewType, editor],
	);

	const onClick = useCallback(() => {
		applyTemplate(templates[0].template);
	}, [applyTemplate, templates]);
	const onSelect = useCallback(
		(key: AriaKey | null) => {
			if (key == null) return;
			const template = templates.find(
				({ label }) => label === key,
			)?.template;

			if (template) {
				applyTemplate(template);
			}
		},
		[applyTemplate, templates],
	);
	const buttonIsDisabled = !isEditorEmpty(editor.getEditorState());

	if (!templates.length) {
		return null;
	}

	if (templates.length === 1) {
		return (
			<IconButton
				disabled={buttonIsDisabled}
				data-for="template-tooltip"
				onClick={onClick}
				onMouseDown={(evt: React.MouseEvent) => {
					// Prevent default on mouse down stops the content editable from blurring
					evt.preventDefault();
				}}
				title={`Insert '${templates[0].label}' template`}
			>
				<TableIcon />
			</IconButton>
		);
	}

	return (
		<Select
			aria-labelledby="note-template-select"
			onSelectionChange={onSelect}
		>
			<TemplateListButton isDisabled={buttonIsDisabled}>
				<TableIcon />
			</TemplateListButton>
			<TemplateListPopover>
				<ListBox>
					{templates.map(({ label }) => (
						<ListBoxItem id={label} key={label} textValue={label}>
							{label}
						</ListBoxItem>
					))}
				</ListBox>
			</TemplateListPopover>
		</Select>
	);
}
