import 'rxjs/add/observable/fromPromise';
import React, { createRef } from 'react';
import { Link } from 'react-router-dom-pinned-version-6';
import { Observable } from 'rxjs/Observable';
import type { Subscription } from 'rxjs/Subscription';
import styled from 'styled-components';

import SpinLoader from '../components/spin-loader';
import { trackEvent } from '../utils/analytics';
import { post } from '../utils/api';

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
`;

type State = {
	error: boolean;
	loading: boolean;
	submitted: boolean;
};

class Reset extends React.Component<Record<string, never>, State> {
	private subscription: null | Subscription = null;
	private emailOrUsername = createRef<HTMLInputElement>();

	state: State = {
		error: false,
		loading: false,
		submitted: false,
	};

	componentDidMount() {
		// This tracks: anytime a user follows the "forgot password" link on the
		// login screen & lands on the "request password reset" page
		trackEvent('Visit New Page', 'request-password-reset-view', 'accounts');
	}

	componentWillUnmount(): void {
		if (this.subscription) this.subscription.unsubscribe();
	}

	handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
		event.preventDefault();

		const emailOrUsername = this.emailOrUsername.current?.value;

		// This tracks: anytime a user submits their username/email to request a
		// new password
		trackEvent(
			'Request Password Reset Email',
			'request-password-reset-button',
			'accounts',
			{
				'Email or Username Entered': emailOrUsername,
			},
		);

		this.setState({ loading: true });

		this.subscription = Observable.fromPromise(
			post('/auth/password/reset', {
				body: { identifier: emailOrUsername },
			}),
		).subscribe(
			() => {
				this.setState({ loading: false, submitted: true });
			},
			() => {
				this.setState({ loading: false, error: true });
			},
		);
	};

	render(): JSX.Element {
		const { error, loading, submitted } = this.state;

		if (loading) {
			return (
				<Container>
					<SpinLoader />
				</Container>
			);
		}

		if (submitted) {
			return (
				<Container>
					<p>
						If the email address is valid, you will receive a reset
						link in your inbox.
					</p>
				</Container>
			);
		}

		return (
			<form className="Login-form" onSubmit={this.handleSubmit}>
				<div className="Form-container">
					<p className="Login-message">
						Please enter your email address.
					</p>

					<input
						autoCapitalize="none"
						autoFocus
						className="Login-form-input"
						placeholder="Email"
						ref={this.emailOrUsername}
						type="text"
					/>
					<input
						className="Login-form-submit"
						type="submit"
						value="Send reset email"
					/>

					{error && (
						<p className="Login-message Login-error">
							An error has occurred. Please try again.
						</p>
					)}

					<p>
						<Link to="/login">Back to login</Link>
					</p>
				</div>
			</form>
		);
	}
}

export default Reset;
