import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom-pinned-version-6';
import styled from 'styled-components';

import { selectUser } from '../../selectors';
import { colors, fonts } from '../../theme';
import {
	Menu,
	MenuButton,
	MenuItems,
	MenuLink,
} from '../../theme/components/menu';

const StyledMenuButton = styled(MenuButton)`
	${fonts.paragraph.paragraph};
	align-items: center;
	background-color: ${colors.layer.layer};
	border-radius: 50%;
	border: unset;
	color: ${colors.text.primary};
	cursor: pointer;
	display: flex;
	height: 32px;
	justify-content: center;
	padding: unset;
	width: 32px;
`;
const StyledMenuItems = styled(MenuItems)`
	${fonts.paragraph.paragraph};
`;

interface Props {
	onClick: () => void;
}

export default function AccountMenu({ onClick }: Props) {
	const user = useSelector(selectUser);

	const firstNameInitial = user
		? user.first_name.slice(0, 1).toUpperCase()
		: '';
	const lastNames = user ? user.last_name.split(' ') : [];
	const lastNameInitials = lastNames.map((name) =>
		name.replace(/[^a-zA-Z]/gu, '').slice(0, 1),
	);
	const initials = `${firstNameInitial}${lastNameInitials.join('')}`;

	return (
		<Menu
			menu={
				<StyledMenuButton onClick={onClick}>
					{initials}
				</StyledMenuButton>
			}
		>
			<StyledMenuItems>
				<MenuLink forwardedAs={Link} to="/account">
					Account
				</MenuLink>
				<MenuLink forwardedAs={Link} to="/logout">
					Log out
				</MenuLink>
			</StyledMenuItems>
		</Menu>
	);
}
