import assertExhaustive from '../../utils/assert-exhaustive';

import CompanyStatusFilterControl from './company-status-filter-control';
import ContactedFilterControl from './contacted-filter-control';
import EmployeeCountFilterControl from './employee-count-filter-control';
import FoundedYearFilterControl from './founded-year-filter-control';
import FunnelActionFilterControl from './funnel-action-filter-control';
import IndustryFilterControl from './industry-filter-control';
import LastRaisedDateFilterControl from './last-raised-date-filter-control';
import LocationFilterControl from './location-filter-control';
import MarketMapsFilterControl from './market-maps-filter-control';
import RoleFilterControl from './role-filter-control';
import SearchQueryStrengthFilterControl from './search-query-strength-control';
import TotalFundingFilterControl from './total-funding-filter-control';
import { type AllFilters } from './types';

export function descriptionParts(filters: AllFilters[]) {
	return filters.reduce((parts, filter) => {
		const { fieldName } = filter;
		switch (fieldName) {
			case 'companyStatus':
				return [
					...parts,
					...CompanyStatusFilterControl.description(filter),
				];
			case 'contacted':
				return [
					...parts,
					...ContactedFilterControl.description(filter),
				];
			case 'employeeCount':
				return [
					...parts,
					...EmployeeCountFilterControl.description(filter),
				];
			case 'foundedYear':
				return [
					...parts,
					...FoundedYearFilterControl.description(filter),
				];
			case 'funnelAction':
				return [
					...parts,
					...FunnelActionFilterControl.description(filter),
				];
			case 'industry':
				return [...parts, ...IndustryFilterControl.description(filter)];
			case 'lastRaisedDate':
				return [
					...parts,
					...LastRaisedDateFilterControl.description(filter),
				];
			case 'location':
				return [...parts, ...LocationFilterControl.description(filter)];
			case 'marketMaps':
				return [
					...parts,
					...MarketMapsFilterControl.description(filter),
				];
			case 'role':
				return [...parts, ...RoleFilterControl.description(filter)];
			case 'searchQueryStrength':
				return [
					...parts,
					...SearchQueryStrengthFilterControl.description(filter),
				];
			case 'totalFunding':
				return [
					...parts,
					...TotalFundingFilterControl.description(filter),
				];
			default:
				throw assertExhaustive(fieldName);
		}
	}, [] as string[]);
}
