import { useEffect } from 'react';
import { useParams } from 'react-router-dom-pinned-version-6';

import { trackEvent } from '../../../utils/analytics';

export default function Analytics() {
	const { name } = useParams<'name'>();

	useEffect(() => {
		if (name) {
			trackEvent('Click Sidebar Tab', `stat-${name}`, 'stats');
		}
	}, [name]);

	return null;
}
