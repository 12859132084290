import type Color from 'color';
import React from 'react';
import type { ComponentProps, ReactNode } from 'react';
import { Link } from 'react-router-dom-pinned-version-6';
import styled from 'styled-components';

import { primaryDriveBlue, white } from '../colors';

const Button = styled.button<{ background: Color; foreground: Color }>`
	background-color: ${({ background }) => background.string()};
	border-radius: 0 3px 3px 0;
	border-width: 0 0 0 1px;
	color: ${({ foreground }) => foreground.string()};
	cursor: pointer;
	font-size: 0;
	height: 100%;
	outline: none;
	padding: 8px 8px 9px;
	transition: background-color 0.1s linear;
	vertical-align: bottom;

	&:hover {
		background-color: ${({ background }) =>
			background.darken(0.1).string()};
	}
`;

const Container = styled.span`
	border-radius: 3px; /* Not visible, but necessary for the box shadow */
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
	display: inline-block;
	margin: 2px;
	white-space: pre;
`;

const Label = styled.span<{ background: Color; foreground: Color }>`
	background-color: ${({ background }) => background.string()};
	border-radius: 3px 0px 0px 3px;
	color: ${({ foreground }) => foreground.string()};
	display: inline-block;
	line-height: 25px;
	padding: 0 10px 2px 12px;
`;

const LinkLabel = styled(Label)<{ radius: string }>`
	cursor: pointer;
	transition: background-color 0.1s;
	border-radius: ${({ radius }) => radius};
	&:hover {
		background-color: ${({ background }) =>
			background.darken(0.1).string()};
	}
`;

const Svg = styled.svg`
	height: 10px;
	width: 8px;
`;

interface Props {
	readonly backgroundColor?: Color;
	// `children` will most often be a `number | string`.
	readonly children: ReactNode;
	readonly color?: Color;
	readonly link?: ComponentProps<typeof Link>['to'];
	readonly onRemove?: () => void;
}

export const Pill = ({
	backgroundColor = primaryDriveBlue,
	color = white,
	children,
	link = '',
	onRemove,
	...props
}: Props): JSX.Element => (
	<Container {...props}>
		{link ? (
			<LinkLabel
				as={Link}
				background={backgroundColor}
				foreground={color}
				to={link}
				radius={onRemove ? '3px 0px 0px 3px' : '3px'}
			>
				{children}
			</LinkLabel>
		) : (
			<Label background={backgroundColor} foreground={color}>
				{children}
			</Label>
		)}
		{onRemove ? (
			<Button
				background={backgroundColor}
				foreground={color}
				onClick={onRemove}
			>
				<Svg viewBox="16 16 30 30">
					<path
						d="M44.9,19.6 40.6,15.4 30.4,26 20.1,15.4 15.8,19.6 26.2,30.3 15.8,41 20.1,45.2 30.4,34.6 40.6,45.2 44.9,41    34.5,30.3"
						fill="currentColor"
					/>
				</Svg>
			</Button>
		) : null}
	</Container>
);

export const Pills = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 0 12px 0px 10px;
`;
