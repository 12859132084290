import { useCallback, useMemo } from 'react';
import {
	NavigateOptions,
	useSearchParams,
} from 'react-router-dom-pinned-version-6';

import ImmutableURLSearchParams from '../immutable-url-search-params';

/**
 * React Router's `useSearchParams` hook but with an immutable-style API.
 *
 * https://reactrouter.com/en/6/hooks/use-search-params
 */
export default function useImmutableSearchParams() {
	const [mutableSearchParams, setMutableSearchParams] = useSearchParams();

	const searchParams = useMemo(
		() => new ImmutableURLSearchParams(mutableSearchParams),
		[mutableSearchParams],
	);

	const setSearchParams = useCallback(
		(
			updater: (
				searchParams: ImmutableURLSearchParams,
			) => ImmutableURLSearchParams,
			navigateOpts?: NavigateOptions,
		) => {
			setMutableSearchParams(
				(currentParams) =>
					updater(
						new ImmutableURLSearchParams(currentParams),
					).toString(),
				navigateOpts,
			);
		},
		[setMutableSearchParams],
	);

	return [searchParams, setSearchParams] as const;
}
