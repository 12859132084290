import React from 'react';
import Media from 'react-media';
import { Link } from 'react-router-dom-pinned-version-6';
import styled from 'styled-components';

import Authorized from '../components/Authorized';
import { breakpoints, colors, fonts } from '../theme';
import { ArrowRightUp } from '../theme/icons/arrows';

import { showNewSearch } from '.';

const BannerContainer = styled.div`
	${fonts.h5.productive};
	align-items: center;
	background-color: ${colors.layer.inverse};
	display: flex;
	justify-content: space-between;

	padding: 8px 16px;
	@media (${breakpoints.xl}) {
		padding: 8px 32px;
	}
`;
const BannerLeft = styled.div`
	color: ${colors.textDark.primary};
	display: flex;
	gap: 2px;

	span:first-child {
		${fonts.paragraph.strong};
	}
	span:last-child {
		${fonts.paragraph.paragraph};
	}
`;
const LinkLeft = styled(Link)`
	${fonts.paragraph.strong};
	text-decoration: underline;
`;

const BannerRight = styled(Link)`
	${fonts.paragraph.underline};
	align-items: center;
	color: ${colors.textDark.interactive};
	display: flex;
	gap: 2px;

	&:hover {
		color: ${colors.textDark.interactiveHover};
	}

	svg {
		height: 16px;
		width: 16px;
	}
`;

export default function Banner() {
	return (
		<Authorized auth={showNewSearch}>
			<BannerContainer>
				<BannerLeft>
					<span>Updates to search are coming soon.</span>
					<Media
						query={`(${breakpoints.md})`}
						render={() => (
							<span>
								<LinkLeft to="/new-search">
									Try the Beta
								</LinkLeft>{' '}
								to experience a more powerful search.
							</span>
						)}
					/>
				</BannerLeft>
				<BannerRight to="/new-search">
					Try Beta Search
					<ArrowRightUp />
				</BannerRight>
			</BannerContainer>
		</Authorized>
	);
}
