import classNames from 'classnames';
import { noop } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom-pinned-version-6';
import styled from 'styled-components';

import {
	AddableSortableHeader,
	SortableHeader,
	sortDirections,
	stickiness,
} from '../';
import type { CellArgs, HeaderArgs, IColumn, SortDirection } from '../';

const WIDTH = 200;
const imageClassName = 'HerbieTable__cell__name_image';
const nameCellClassName = 'HerbieTable__NameCell';

const Text = styled.span`
	display: block;
	overflow: hidden;
	padding: 5px 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const StyledLink = styled(Link)`
	color: inherit;
	display: block;
	overflow: hidden;
	padding: 5px 10px;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;

	&:hover {
		text-decoration: underline;
	}
`;

const Image = styled.img`
	border-radius: 50%;
	height: 20px;
	margin-right: 4px;
	max-width: 20px;
	min-width: 20px;
	object-fit: contain;
	object-position: center;
	vertical-align: top;
`;

const NameCell = styled.td`
	max-width: ${WIDTH}px;
`;

interface Data {
	readonly href: string | null;
	readonly imageUrl?: string;
	readonly name: string;
}

export class NonStickyNameColumn<T> implements IColumn<T> {
	private handleMouseDownEvent: (row: T) => void;
	private select: (row: T) => Data;
	private types: Array<string>;
	name: string;

	constructor({
		handleMouseDownEvent = () => noop,
		name = 'Name',
		select,
		types = ['person', 'company'],
	}: {
		handleMouseDownEvent?: (row: T) => void;
		select: (row: T) => Data;
		types?: Array<string>;
		name?: string;
	}) {
		this.handleMouseDownEvent = handleMouseDownEvent;
		this.select = select;
		this.types = types;
		this.name = name;

		this.cell.displayName = 'NameColumn';
	}

	// eslint-disable-next-line react/display-name
	cell = React.memo(({ props, row }: CellArgs<T>) => {
		const data = this.select(row);
		const className = classNames(props.className, nameCellClassName);

		return (
			<NameCell {...props} className={className}>
				{data.href ? (
					<StyledLink
						to={data.href}
						title={data.name}
						onMouseDown={(e) => {
							e.stopPropagation();
							this.handleMouseDownEvent(row);
						}}
					>
						{data.imageUrl && (
							<Image
								className={imageClassName}
								src={data.imageUrl}
							/>
						)}
						{data.name}
					</StyledLink>
				) : (
					<Text>{data.name}</Text>
				)}
			</NameCell>
		);
	});

	header({ onAdd, onSort, props, sort, sortIndex }: HeaderArgs): JSX.Element {
		if (onAdd) {
			return (
				<AddableSortableHeader
					onAdd={onAdd}
					onSort={onSort}
					sort={sort}
					sortIndex={sortIndex}
					types={this.types}
					{...props}
				>
					{this.name}
				</AddableSortableHeader>
			);
		}
		return (
			<SortableHeader
				onSort={onSort}
				sort={sort}
				sortIndex={sortIndex}
				{...props}
			>
				{this.name}
			</SortableHeader>
		);
	}

	search(query: string, row: T): boolean {
		const data = this.select(row);
		return data.name.toLowerCase().includes(query);
	}

	sort(direction: SortDirection, a: T, b: T): number {
		const aName = this.select(a).name.toLowerCase();
		const bName = this.select(b).name.toLowerCase();

		if (aName === bName) return 0;
		if (aName === '') return 1;
		if (bName === '') return -1;
		return direction === sortDirections.ascending
			? aName.localeCompare(bName)
			: bName.localeCompare(aName);
	}

	toCSV(row: T): string {
		const data = this.select(row);
		return data.name;
	}
}

export default class NameColumn<T> extends NonStickyNameColumn<T> {
	readonly position = {
		sticky: stickiness.left,
		width: WIDTH,
	};
}
