import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useUser } from '../authentication';
import {
	isInvestor,
	isSeedManager,
	or,
	useAuthorized,
} from '../components/Authorized';
import Editor, {
	deserializeEditorState,
	EditorState,
	getTextContent,
	isEditorEmpty,
	serializeEditorState,
	TemplatePlugin,
	useEditorClear,
} from '../components/text-editor';
import { colors } from '../theme';
import UnstyledIconButton from '../theme/components/icon-button';
import { PaperAirplane1Outline as AirplaneIcon } from '../theme/icons/arrows';
import {
	LockClosedLineIcon as PrivateIcon,
	LockOpenIconIcon as PublicIcon,
} from '../theme/icons/system';
import { trackEvent } from '../utils/analytics';
import useLocalStorage from '../utils/hooks/use-local-storage';

import ContactedDropdown, { OTHER_OPTION } from './contacted-dropdown';
import Reminder from './reminder';
import { Container } from './shared-components';
import { GROWTH_COMPANY_NOTES, USER_TEMPLATES } from './templates';
import { type NoteData, type ProfileType, type ReminderData } from './types';

const AddIconButton = styled(UnstyledIconButton)`
	align-items: center;
	background: ${colors.button.primary};
	border: 1px solid ${colors.button.primary};
	border-radius: 8px;
	color: ${colors.icon.onColor};
	display: flex;
	justify-content: center;

	svg {
		height: 16px;
		width: 16px;
	}

	&:hover {
		color: ${({ disabled }) =>
			disabled ? 'inherit' : `${colors.icon.onColor};`};
	}
`;
const PrivacyIconButton = styled(UnstyledIconButton)`
	border-radius: 8px;

	svg {
		height: 16px;
		width: 16px;
	}
`;
const Footer = styled.div`
	align-items: flex-start;
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
`;

function AddButton({
	buttonSize,
	disabled,
	onClick,
}: {
	buttonSize: 'default' | 'small';
	disabled: boolean;
	onClick(): Promise<void>;
}) {
	const clearEditor = useEditorClear();
	const handleClick = useCallback(async () => {
		await onClick();
		clearEditor();
	}, [clearEditor, onClick]);

	return (
		<AddIconButton
			disabled={disabled}
			onClick={handleClick}
			size={buttonSize}
		>
			<AirplaneIcon />
		</AddIconButton>
	);
}

interface Props {
	autofocus?: boolean;
	buttonSize?: 'default' | 'small';
	footerVisible?: boolean;
	isCreating: boolean;
	mixpanelIdentifier: string;
	namespace: string;
	onCreate: (
		note: Pick<
			NoteData,
			'comment' | 'contacted_via' | 'public' | 'raw_comment' | 'reminders'
		>,
	) => Promise<NoteData | NoteData[]>;
	placeholder?: string;
	profileType: ProfileType;
	toolbarOpen?: boolean;
}

const DEFAULT_CONTACTED_VIA = '';
const DEFAULT_PUBLIC = true;
export default function NewNote({
	autofocus = false,
	buttonSize = 'default',
	footerVisible = true,
	isCreating,
	mixpanelIdentifier,
	namespace,
	onCreate,
	placeholder = 'Add a note here...',
	profileType,
	toolbarOpen = true,
}: Props) {
	const [serializedEditorState, setSerializedEditorState, clear] =
		useLocalStorage(namespace, '');
	const [comment, setComment] = useState(
		deserializeEditorState(serializedEditorState),
	);

	const [contactedVia, setContactedVia] = useState(DEFAULT_CONTACTED_VIA);
	const [dirty, setDirty] = useState(serializedEditorState !== '');
	const [isPublic, setIsPublic] = useState(DEFAULT_PUBLIC);
	const [reminder, setReminder] = useState<ReminderData | null>(null);
	const handleCommentChange = useCallback(
		(editorState: EditorState) => {
			if (!isEditorEmpty(editorState)) {
				setDirty(true);
				setSerializedEditorState(serializeEditorState(editorState));
				setComment(getTextContent(editorState));
			} else {
				// Do our best not to accumulate tons of empty editor states in localStorage
				// If the editor is cleared completely, remove the key
				clear();
				setComment('');
				setDirty(false);
			}
		},
		[clear, setSerializedEditorState],
	);
	const createDisabled =
		!dirty || isCreating || contactedVia === OTHER_OPTION;
	const handleCreate = useCallback(
		async (clearEditor?: () => void) => {
			if (createDisabled) return;
			await onCreate({
				comment,
				contacted_via: contactedVia,
				public: isPublic,
				raw_comment: serializedEditorState,
				reminders: reminder ? [reminder] : [],
			});

			trackEvent(
				'Add Note',
				mixpanelIdentifier,
				profileType === 'lpfundraising'
					? 'lp-fundraising'
					: profileType,
				{
					contacted_via: contactedVia,
					notification_method: reminder?.notification_method || null,
					public: isPublic,
				},
			);

			if (clearEditor) clearEditor();
			clear();
			setContactedVia(DEFAULT_CONTACTED_VIA);
			setDirty(false);
			setIsPublic(DEFAULT_PUBLIC);
			setReminder(null);
		},
		[
			clear,
			comment,
			contactedVia,
			createDisabled,
			isPublic,
			mixpanelIdentifier,
			onCreate,
			profileType,
			reminder,
			serializedEditorState,
		],
	);
	const user = useUser();
	const userDefaultTemplate =
		user.username in USER_TEMPLATES
			? USER_TEMPLATES[user.username as keyof typeof USER_TEMPLATES]
			: null;
	const useGrowthTemplate = useAuthorized(or(isInvestor, isSeedManager));

	const templates = [];
	if (userDefaultTemplate) {
		templates.push({
			label: 'Your Default Template',
			template: userDefaultTemplate,
		});
	}
	if (useGrowthTemplate) {
		templates.push(GROWTH_COMPANY_NOTES);
	}

	return (
		<Container className="NewNote">
			<Editor
				analyticsComponentIdentifier={mixpanelIdentifier}
				analyticsViewType={
					profileType === 'lpfundraising'
						? 'lp-fundraising'
						: profileType
				}
				editable
				autofocus={autofocus}
				control={
					<AddButton
						buttonSize={buttonSize}
						disabled={createDisabled}
						onClick={handleCreate}
					/>
				}
				extraToolbarControls={
					<TemplatePlugin
						analyticsComponentIdentifier={mixpanelIdentifier}
						analyticsViewType={
							profileType === 'lpfundraising'
								? 'lp-fundraising'
								: profileType
						}
						templates={templates}
					/>
				}
				footer={
					footerVisible && (
						<Footer>
							<PrivacyIconButton
								title={
									isPublic ? 'Private: On' : 'Private: Off'
								}
								onClick={() => {
									setIsPublic((prev) => !prev);
								}}
								size={buttonSize}
							>
								{isPublic ? <PublicIcon /> : <PrivateIcon />}
							</PrivacyIconButton>
							<Reminder
								disabled={isCreating}
								onChange={setReminder}
								reminder={reminder}
							/>
							<ContactedDropdown
								contactedVia={contactedVia}
								disabled={isCreating}
								onChange={setContactedVia}
							/>
						</Footer>
					)
				}
				initialContent={serializedEditorState}
				namespace={namespace}
				onChange={handleCommentChange}
				onEnter={handleCreate}
				placeholder={placeholder}
				toolbarOpen={toolbarOpen}
			/>
		</Container>
	);
}
