import { Temporal } from '@js-temporal/polyfill';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom-pinned-version-6';
import styled, { css } from 'styled-components';

import { useUser } from '../authentication';
import Authorized from '../components/Authorized';
import Header from '../components/header';
import { path as marketMapPath, useMarketMaps } from '../market-maps';
import { BetaChip, showNewSearch } from '../new-search';
import { QuickSearch } from '../search';
import { breakpoints, colors, effects, fonts } from '../theme';
import useMediaQuery from '../utils/hooks/use-media-query';

import UnstyledCreateMarketMap from './create-market-map';
import OutreachSection from './outreach-section';

const View = styled.div`
	background-color: ${colors.background.background};
	display: flex;
	flex-direction: column;
	gap: 48px;
	overflow: scroll;
	padding-bottom: 48px;

	@media (${breakpoints.lg}) {
		gap: 96px;
	}
`;

const Main = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	flex: 1;
	gap: 32px;
`;

const Welcome = styled.div`
	${fonts.h2.productive};
	color: ${colors.text.primary};
`;

const Search = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 90vw;

	@media (${breakpoints.md}) {
		min-width: 480px;
		width: unset;
	}
`;

const AdvancedSearchText = styled.div`
	${fonts.paragraph.paragraph};
	align-content: center;
	color: ${colors.text.secondary};
	display: flex;
	padding: 4px 0;
`;

const MarketMapsSection = styled.div<{ $enabledBreakpoint: string }>`
	display: none;
	height: 82px;
	position: relative;
	text-align: center;

	@media (${({ $enabledBreakpoint }) => $enabledBreakpoint}) {
		display: block;
	}
`;
const MarketMapCards = styled.div`
	display: grid;
	gap: 16px;
	grid-auto-flow: column;
`;

const marketMapCardStyles = css`
	${effects.shadow.shadow};
	align-self: center;
	background-color: ${colors.card.card};
	border-radius: 8px;
	border: 1px solid ${colors.border.subtle};
	display: grid;
	gap: 4px;
	overflow: hidden;
	padding: 12px;
	position: relative;
	width: 108px;

	&:hover {
		border-color: ${colors.border.subtleHover};
		${effects.shadow.hover};
	}
`;
const CreateMarketMap = styled(UnstyledCreateMarketMap)`
	${marketMapCardStyles};
	border-style: dashed;
	height: 100%;
`;

const MarketMapCard = styled(Link)`
	${marketMapCardStyles};
`;

const MarketMapCardTitle = styled.div`
	${fonts.caption.caption};
	color: ${colors.text.secondary};
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	white-space: nowrap;
`;
const MarketMapCardImage = styled.picture`
	font-size: 0;
	height: 40px;
	justify-self: center;
	position: relative;
	width: 40px;

	img {
		height: 40px;
		width: 40px;

		&[src=''] {
			display: none;
		}
	}

	&[data-src='']:after {
		${fonts.h4.expressive};
		align-items: center;
		background: ${colors.background.wrapper};
		border-radius: 50%;
		color: ${colors.text.primary};
		content: attr(data-alt);
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
`;
const NoMarketMapsCopy = styled.div`
	${fonts.h5.productive}
	align-items: center;
	color: ${colors.text.secondary};
	display: flex;
	margin-top: -16px;
	gap: 8px;
`;

export default function InvestorOutreachView() {
	const user = useUser();

	const wideEnoughForMarketMaps = useMediaQuery(`(${breakpoints.lg})`);
	const {
		data: marketMaps,
		isLoading: marketMapsLoading,
		isError: marketMapsError,
	} = useMarketMaps(
		{ enabled: wideEnoughForMarketMaps, staleTime: 0 },
		{ owner: user.id, viewed_by: user.id },
	);

	const userMarketMaps = useMemo(() => {
		if (marketMaps) {
			return marketMaps
				.filter((map) => map.status === 'ACTIVE')
				.toSorted(
					(
						{
							added_at: addedA,
							last_viewed_at: viewedA,
							owner: ownerA,
						},
						{
							added_at: addedB,
							last_viewed_at: viewedB,
							owner: ownerB,
						},
					) => {
						if (ownerA.id === user.id && ownerB.id !== user.id) {
							return -1;
						} else if (
							ownerA.id !== user.id
							&& ownerB.id === user.id
						) {
							return 1;
						} else {
							return Temporal.Instant.compare(
								viewedB || addedB,
								viewedA || addedA,
							);
						}
					},
				)
				.slice(0, 6);
		}
		return [];
	}, [marketMaps, user.id]);

	const successfulMarketMapsRequest = !marketMapsLoading && !marketMapsError;
	const renderNoMarketMapsCopy =
		successfulMarketMapsRequest && userMarketMaps.length === 0;
	const renderMarketMapCards =
		successfulMarketMapsRequest && userMarketMaps.length > 0;

	return (
		<View>
			<div>
				<Header search={false} />
			</div>
			<Main>
				<Welcome>Welcome, {user.first_name}</Welcome>
				<Search>
					<QuickSearch
						autoFocus
						placeholder="Search companies and people"
					/>
					<AdvancedSearchText>
						Explore companies and people using the new&nbsp;
						<Authorized auth={showNewSearch}>
							<BetaChip />
						</Authorized>
						&nbsp;experience.
					</AdvancedSearchText>
				</Search>
				<MarketMapsSection $enabledBreakpoint={breakpoints.lg}>
					{renderNoMarketMapsCopy && (
						<NoMarketMapsCopy>
							Click + to create your next market map
							<CreateMarketMap />
						</NoMarketMapsCopy>
					)}
					{renderMarketMapCards && (
						<MarketMapCards>
							{userMarketMaps.map((marketMap) => (
								<MarketMapCard
									key={marketMap.id}
									title={marketMap.name}
									to={`/${marketMapPath}/${marketMap.id}`}
								>
									<MarketMapCardImage
										data-src={marketMap.icon_url}
										data-alt={marketMap.name.slice(0, 1)}
									>
										<img src={marketMap.icon_url} />
									</MarketMapCardImage>
									<MarketMapCardTitle>
										{marketMap.name}
									</MarketMapCardTitle>
								</MarketMapCard>
							))}
							<CreateMarketMap />
						</MarketMapCards>
					)}
				</MarketMapsSection>
				<OutreachSection />
			</Main>
		</View>
	);
}
