import React, { useCallback } from 'react';
import styled from 'styled-components';

import {
	Label,
	Radio,
	RadioGroup as UnstyledRadioGroup,
} from '../../components/radio-group';

import {
	type FilterControlHeaderProps,
	type FilterControlProps,
	type IFilterControl,
} from './control';
import FilterControlHeader from './filter-control-header';
import { type FunnelActionFilter } from './types';

const RadioGroup = styled(UnstyledRadioGroup)``;

const FIELD_NAME: FunnelActionFilter['fieldName'] = 'funnelAction';
function FilterControl({
	filter,
	onChange,
}: FilterControlProps<FunnelActionFilter>) {
	return (
		<RadioGroup
			label="High Beam Status"
			onChange={(value: string) =>
				value != null
				&& onChange({
					fieldName: FIELD_NAME,
					value: value as FunnelActionFilter['value'],
				})
			}
			value={filter?.value ?? null}
		>
			<Label>Current Status</Label>
			<Radio value="ACTIVE">Active</Radio>
			<Radio value="SNOOZED">Snoozed</Radio>
		</RadioGroup>
	);
}

function Header({
	filter,
	isOpen,
	onClear,
	onOpenToggle,
}: FilterControlHeaderProps<FunnelActionFilter>) {
	const handleClear = useCallback(() => {
		onClear(FIELD_NAME);
	}, [onClear]);
	const handleOpenToggle = useCallback(() => {
		onOpenToggle(FIELD_NAME);
	}, [onOpenToggle]);

	return (
		<FilterControlHeader
			hasValue={Boolean(filter)}
			isOpen={isOpen}
			onClear={handleClear}
			onOpenToggle={handleOpenToggle}
			title="My High Beam Pipeline"
		/>
	);
}

const Control: IFilterControl<FunnelActionFilter> = {
	control: FilterControl,
	description: (filter) => {
		if (filter.value === 'ACTIVE') {
			return ['are in my pipeline'];
		}
		if (filter.value === 'SNOOZED') {
			return ["I've snoozed"];
		}
		return [];
	},
	fieldName: FIELD_NAME,
	header: Header,
} as const;

export default Control;
