import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom-pinned-version-6';
import styled from 'styled-components';

import { colors, fonts } from '../theme';
import ButtonComponent from '../theme/components/button';
import { ArrowLoopRightIcon } from '../theme/icons/arrows';

const Container = styled.div`
	${fonts.paragraph.paragraph};
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 16px;
	min-height: 75vh;
	text-align: center;

	h3 {
		${fonts.h3.productive};
	}

	a {
		color: ${colors.text.interactive};
	}

	button {
		svg {
			color: ${colors.icon.primary};
			height: 14px;
			width: 14px;
		}

		&:disabled {
			svg {
				color: ${colors.icon.disabled};
			}
		}
	}
`;

interface Props {
	className?: string;
	isRefreshDisabled?: boolean;
	onRefresh?: () => void;
}

export default function ErrorFallback({
	className,
	isRefreshDisabled = false,
	onRefresh,
}: Props) {
	const navigate = useNavigate();

	const handleRefresh = useCallback(() => {
		if (!onRefresh) {
			navigate(0);
		} else {
			onRefresh();
		}
	}, [navigate, onRefresh]);

	return (
		<Container className={className}>
			<img
				src="https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/figma/herbie-repair-shop.svg"
				alt="Herbie Repair Shop"
			/>
			<h3>Something Broke</h3>
			<p>
				Try refreshing the page. If the error persists, please reach out
				to the Herbie team (
				<a href="mailto:herbie-support@drivecapital.com">
					herbie-support@drivecapital.com
				</a>
				).
			</p>
			<ButtonComponent
				disabled={isRefreshDisabled}
				onClick={handleRefresh}
				variant="secondary"
			>
				<ArrowLoopRightIcon />
				Refresh
			</ButtonComponent>
		</Container>
	);
}
