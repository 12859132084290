// @flow

import moment from 'moment';
import numeral from 'numeral';
import querystring from 'querystring';
import React, { type Node as ReactNode, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom-pinned-version-6';
import styled from 'styled-components';

import {
	charcoalGray,
	driveGreen,
	herbieSmartTeal,
	primaryDriveBlue,
	red,
	secondaryDarkDriveBlue,
	secondaryGray,
} from '../../colors';
import Authorized, { hasConnectedInbox } from '../../components/Authorized';
import { downwardsArrow, noBreakSpace, upwardsArrow } from '../../utils/chars';
import getDomain from '../../utils/getDomain';
import {
	type AlgoliaCompanyHit,
	type AlgoliaHit,
	type AlgoliaPersonHit,
	type HighlightResult,
} from '../behavior';

import Highlight, {
	getBestHighlightResultValue,
	HighlightExcerpt,
	unhighlight,
} from './highlight';

const Avatar = styled.img`
	border-radius: 42px;
	height: 84px;
	object-fit: contain;
	object-position: 50% 50%;
	width: 84px;
	background: white;
	border: 1px solid gray;
	margin-right: 12px;
	flex-shrink: 0;
`;

const Container = styled<{ selected?: boolean }, HTMLDivElement>('div')`
	background-color: ${({ selected }) => (selected ? '#f0f0f0' : 'white')};
	border: 1px solid ${secondaryGray.string()};
	border-radius: 2px;
	column-gap: 30px;
	cursor: pointer;
	display: grid;
	grid:
		'key info' auto
		'highlight highlight' auto
		/* Give each column exactly half of the space after accounting for the
		 * the 30px column gap. */
		/ calc(50% - 15px) calc(50% - 15px);
	margin-bottom: 20px;
	min-height: 100px;
	padding: 20px 16px;

	@media (max-width: 850px) {
		grid:
			'key' auto
			'info' auto
			'highlight' auto
			/ auto;
		row-gap: 12px;
	}
`;

const Counts = styled.div`
	margin-top: 4px;
	display: flex;
	flex-direction: row;
	font-size: 15px;
`;

const EmailCount = styled.div`
	flex: 1;
	display: block;
	background-image: url(https://drivecapital-static-assets.s3.us-east-2.amazonaws.com/ic_%2Bemailcount.png);
	background-repeat: no-repeat;
	padding: 4px 12px;
`;

const HighlightArea = styled.div`
	grid-area: highlight;
`;

const HighlightParagraph = styled.p`
	margin-top: 0.75em;
`;

const InfoArea = styled.div`
	color: ${charcoalGray.string()};
	font-size: 16px;
	font-weight: 300;
	grid-area: info;
`;

const InfoTime = styled.span`
	font-style: italic;
`;

const InfoTitle = styled.span`
	font-weight: 400;
`;

const KeyInfo = styled.div`
	display: flex;
	flex-direction: row;
	color: ${charcoalGray.string()};
	overflow: hidden;
`;

const KeyText = styled.div`
	overflow: hidden;
`;

const KeyArea = styled.div`
	display: flex;
	flex-direction: column;
	grid-area: key;
`;

const Location = styled.p`
	color: ${charcoalGray.string()};
`;

const Name = styled.h3`
	font-size: 18px;
	font-weight: normal;

	strong {
		font-weight: bold;
	}
`;

const Tag = styled<{ color: string }, HTMLSpanElement>('span')`
	background-color: ${(props) => props.color};
	border-radius: 3px;
	color: white;
	display: inline-block;
	margin-bottom: 4px;
	margin-right: 6px;
	padding: 4px 10px 6px 12px;
`;

// This is similar to a Tag, but the only clickable tag belongs to Herbie's
// NLP-detected role categories, so we hard-code the color and give it a
// use-specific name.
const RoleLink = styled(Link)`
	background-color: ${herbieSmartTeal.string()};
	border-radius: 4px;
	color: white;
	display: inline-block;
	font-size: 0.8em;
	margin-bottom: 4px;
	margin-right: 6px;
	padding: 6px 12px;
	text-transform: uppercase;
	transition: background-color 0.1s linear;

	:hover {
		background-color: ${herbieSmartTeal.darken(0.1).string()};
	}
`;

const Tags = styled.div`
	margin-top: 10px;
`;

const WebAddress = styled.p`
	color: ${primaryDriveBlue.string()};
	overflow: hidden;
	text-overflow: ellipsis;
`;

type Props = {|
	onSelect: (type: 'companies' | 'people', id: number) => void,
	result: AlgoliaHit,
	selected: boolean,
|};

export default function Result({ onSelect, result, selected }: Props) {
	const navigate = useNavigate();
	const onClick = useCallback(
		({
			shiftKey,
			metaKey,
			target,
		}: SyntheticMouseEvent<HTMLDivElement>): void => {
			if (target instanceof HTMLElement && target.tagName !== 'A') {
				if (shiftKey) {
					onSelect(result.type, result.id);
				} else if (metaKey) {
					window.open(`/${result.type}/${result.id}`);
				} else {
					navigate(`/${result.type}/${result.id}`);
				}
			}
		},
		[navigate, onSelect, result],
	);
	switch (result.type) {
		case 'companies':
			return (
				<CompanyResult
					onClick={onClick}
					result={result}
					selected={selected}
				/>
			);
		case 'people':
			return (
				<PersonResult
					onClick={onClick}
					result={result}
					selected={selected}
				/>
			);
		default:
			throw new Error(`Unknown result type ${result.type}.`);
	}
}

type CompanyResultProps = {|
	onClick: (event: SyntheticMouseEvent<HTMLDivElement>) => void,
	result: AlgoliaCompanyHit,
	selected: boolean,
|};

function CompanyResult({ onClick, result, selected }: CompanyResultProps) {
	const {
		employee_count: employeeCount,
		founding,
		funding,
		homepage_url: homepage,
		last_contact_with_drive: lastContact,
		last_fund_raised: lastFund,
		last_funding_round: lastFundingRound,
		location,
		logo_url: logo,
		name,
	} = result.data;

	let locationDisplay: string = '';
	if (location.length === 1) {
		locationDisplay = location[0];
	} else if (location.length > 1) {
		locationDisplay = `${location[0]} (+ ${location.length - 1} more)`;
	}

	return (
		<Container onClick={onClick} selected={selected}>
			<KeyArea>
				<KeyInfo>
					<Avatar alt={name} src={logo} />
					<KeyText>
						<Name>
							{result._highlightResult != null
							&& result._highlightResult.data != null
							&& result._highlightResult.data.name != null ? (
								<Highlight
									string={
										result._highlightResult.data.name.value
									}
								/>
							) : (
								name
							)}
						</Name>
						{homepage && (
							<WebAddress>{getDomain(homepage)}</WebAddress>
						)}
						{locationDisplay && (
							<Location>
								<Highlight
									string={getBestHighlightResultValue(
										result._highlightResult != null
											&& result._highlightResult.data
												!= null
											&& Array.isArray(
												result._highlightResult.data
													.location,
											)
											? result._highlightResult.data
													.location
											: [],
										locationDisplay,
									)}
								/>
							</Location>
						)}
					</KeyText>
				</KeyInfo>
				{result._highlightResult != null
					&& Array.isArray(result._highlightResult.market_maps) && (
						<Tags>
							{result._highlightResult.market_maps
								.filter(
									(marketMap) =>
										marketMap.matchLevel !== 'none',
								)
								.sort((a, b) =>
									unhighlight(a.value).localeCompare(
										unhighlight(b.value),
									),
								)
								.map((marketMap) => (
									<Tag
										color={driveGreen.string()}
										key={marketMap.value}
									>
										<Highlight string={marketMap.value} />
									</Tag>
								))}
						</Tags>
					)}
			</KeyArea>
			<InfoArea>
				{founding != null && founding !== 9000 && (
					<InfoItem title="Founded">{founding}</InfoItem>
				)}
				{employeeCount != null && (
					<InfoItem title="Headcount">
						{employeeCount}
						<EmployeeChange
							change={
								result.data.employee_change_one_month != null
									? result.data.employee_change_one_month
									: result.data.employee_change
							}
						/>
					</InfoItem>
				)}
				{funding !== null && (
					<InfoItem title="Total funding">
						{numeral(funding).format('$0[.]0a').toUpperCase()}
					</InfoItem>
				)}
				{lastFund && (
					<InfoItem title="Last Fund Raised">
						<InfoTime>{moment(lastFund).fromNow()}</InfoTime>
					</InfoItem>
				)}
				{lastFundingRound && (
					<InfoItem title="Last Funding Round">
						<InfoTime>
							{moment(lastFundingRound).fromNow()}
						</InfoTime>
					</InfoItem>
				)}
				<InfoItem title="Last Drive Contact">
					<InfoTime>
						{lastContact ? moment(lastContact).fromNow() : 'N/A'}
					</InfoTime>
				</InfoItem>
			</InfoArea>
			<HighlightArea>
				{result._highlightResult != null
					&& result._highlightResult.data != null
					&& result._highlightResult.data.description != null
					&& result._highlightResult.data.description.matchLevel
						!== 'none' && (
						<HighlightParagraph>
							<HighlightExcerpt
								string={
									result._highlightResult.data.description
										.value
								}
							/>
						</HighlightParagraph>
					)}
				{result._highlightResult != null
					&& result._highlightResult.notes
					&& result._highlightResult.notes.some(
						(note) => note.matchLevel !== 'none',
					)
					&& result._highlightResult.notes
						.filter((note) => note.matchLevel !== 'none')
						.map((note) => {
							// From the backend, all notes in Algolia begin with
							// `{author} note on {MMMM DD, YYYY}: `. We always
							// want to show this contextual prefix in addition
							// to excerpts from the note body, so split it out.
							// A more thorough solution might use a regular
							// expression, but this will work as long as users'
							// names don't contain `": "`.
							const prefixCharacterCount =
								note.value.indexOf(': ');
							const authorAndDate = note.value.slice(
								0,
								prefixCharacterCount,
							);
							const body = note.value.slice(
								prefixCharacterCount + 2,
							);
							return (
								<HighlightParagraph
									key={note.value}
									title={unhighlight(note.value)}
								>
									<Highlight string={authorAndDate} />:{' '}
									<HighlightExcerpt string={body} />
								</HighlightParagraph>
							);
						})}
			</HighlightArea>
		</Container>
	);
}

function EmployeeChange({ change }: {| change?: number | null |}) {
	if (change == null) {
		return null;
	} else if (change > 0) {
		return (
			<span style={{ color: driveGreen }}>
				{noBreakSpace}({upwardsArrow}
				{change.toPrecision(2)}%)
			</span>
		);
	} else if (change < 0) {
		return (
			<span style={{ color: red }}>
				{noBreakSpace}({downwardsArrow}
				{Math.abs(change).toPrecision(2)}%)
			</span>
		);
	} else {
		// change === 0
		return <span>{noBreakSpace}(no change)</span>;
	}
}

function InfoItem({
	children,
	title,
}: {|
	children: ReactNode,
	title: string,
|}) {
	return (
		<p>
			<InfoTitle>{title}: </InfoTitle>
			{children}
		</p>
	);
}

type PersonResultProps = {|
	onClick: (event: SyntheticMouseEvent<HTMLDivElement>) => void,
	result: AlgoliaPersonHit,
	selected: boolean,
|};

function PersonResult({ onClick, result, selected }: PersonResultProps) {
	const {
		best_relationship,
		current_company,
		current_location: currentLocation,
		first_name: firstName,
		last_name: lastName,
		num_total_emails: numEmails,
		photo_url: photo,
		primary_email: email,
		roles,
	} = result.data;

	let companyStatus = null;
	if (current_company) {
		companyStatus = current_company.name;
		if (current_company.total > 1) {
			companyStatus += ` (+${current_company.total - 1} more)`;
		}
	}

	const name: string = `${firstName} ${lastName}`;

	return (
		<Container onClick={onClick} selected={selected}>
			<KeyArea>
				<KeyInfo>
					<div className="Result-image-info">
						<Avatar alt={name} src={photo} />
						<Authorized auth={hasConnectedInbox}>
							<Counts>
								{numEmails ? (
									<EmailCount
										title={`${numEmails} total emails`}
									>
										{numeral(numEmails).format('0a')}
									</EmailCount>
								) : null}
							</Counts>
						</Authorized>
					</div>
					<KeyText>
						<Name>
							{result._highlightResult != null
							&& result._highlightResult.data != null
							&& result._highlightResult.data.name != null ? (
								<Highlight
									string={
										result._highlightResult.data.name.value
									}
								/>
							) : (
								name
							)}
						</Name>
						{email && (
							<Authorized auth={hasConnectedInbox}>
								<WebAddress>{email.email}</WebAddress>
							</Authorized>
						)}
						{currentLocation && (
							<Location>
								<Highlight
									string={getBestHighlightResultValue(
										result._highlightResult != null
											&& result._highlightResult.data
												!= null
											? result._highlightResult.data
													.location
											: [],
										currentLocation,
									)}
								/>
							</Location>
						)}
					</KeyText>
				</KeyInfo>
				<JobRoles
					highlightedJobs={
						result._highlightResult && result._highlightResult.jobs
					}
					roles={roles}
				/>
			</KeyArea>
			<InfoArea>
				<Authorized auth="contacts.contactrelationships">
					<InfoItem title="Best Contact">
						{best_relationship.best_contact_data}
					</InfoItem>
				</Authorized>
				{best_relationship.last_contact_date && (
					<Authorized auth="contacts.contactrelationships">
						<InfoItem title="Last Contact">
							<InfoTime>
								{moment(
									best_relationship.last_contact_date,
								).fromNow()}
							</InfoTime>
						</InfoItem>
					</Authorized>
				)}
				{companyStatus && (
					<InfoItem title="Current Company">{companyStatus}</InfoItem>
				)}
			</InfoArea>
			<HighlightArea>
				{result._highlightResult != null
					&& Array.isArray(result._highlightResult.notes)
					&& result._highlightResult.notes.some(
						(note) => note.matchLevel !== 'none',
					)
					&& result._highlightResult.notes
						.filter((note) => note.matchLevel !== 'none')
						.map((note) => {
							// From the backend, all notes in Algolia begin with
							// `{author} note on {MMMM DD, YYYY}: `. We always
							// want to show this contextual prefix in addition
							// to excerpts from the note body, so split it out.
							// A more thorough solution might use a regular
							// expression, but this will work as long as users'
							// names don't contain `": "`.
							const prefixCharacterCount =
								note.value.indexOf(': ');
							const authorAndDate = note.value.slice(
								0,
								prefixCharacterCount,
							);
							const body = note.value.slice(
								prefixCharacterCount + 2,
							);
							return (
								<HighlightParagraph
									key={note.value}
									title={unhighlight(note.value)}
								>
									<Highlight string={authorAndDate} />:{' '}
									<HighlightExcerpt string={body} />
								</HighlightParagraph>
							);
						})}
			</HighlightArea>
		</Container>
	);
}

type JobRolesProps = {|
	highlightedJobs?: Array<HighlightResult>,
	roles: Array<string>,
|};

function JobRoles({ highlightedJobs, roles }: JobRolesProps) {
	if (Array.isArray(highlightedJobs) && highlightedJobs.length > 0) {
		// The backend sorts these job strings from newest to oldest. Show the
		// first full match if we have it.
		const fullMatch = highlightedJobs.find(
			(job) => job.matchLevel === 'full',
		);
		if (fullMatch) {
			return (
				<Tags>
					<Tag color={secondaryDarkDriveBlue.string()}>
						<Highlight string={fullMatch.value} />
					</Tag>
				</Tags>
			);
		}

		// If we don't have a full match that contains every search term, we can
		// try to show all of the next-best results that are closest to matching
		// all of the search terms.
		const maxMatchedWords = Math.max(
			...highlightedJobs.map((job) => job.matchedWords.length),
		);
		const partialMatches = highlightedJobs.filter(
			(job) =>
				job.matchLevel === 'partial'
				&& job.matchedWords.length === maxMatchedWords,
		);
		if (partialMatches.length > 0) {
			return (
				<Tags>
					{partialMatches.map((job) => (
						<Tag
							color={secondaryDarkDriveBlue.string()}
							key={job.value}
						>
							<Highlight string={job.value} />
						</Tag>
					))}
				</Tags>
			);
		}
	}

	if (!Array.isArray(roles)) {
		return null;
	}

	return (
		<Tags>
			{roles
				.slice()
				.sort((a, b) => a.localeCompare(b))
				.map((role) => (
					<Authorized
						auth="contacts.advanced_person_search"
						key={role}
					>
						{(authorized: boolean) =>
							authorized ? (
								<RoleLink
									to={{
										pathname: '/search',
										search: querystring.stringify({
											'data.roles': role,
											tags: ['person'],
										}),
									}}
								>
									{role}
								</RoleLink>
							) : (
								<Tag color={herbieSmartTeal.string()}>
									{role}
								</Tag>
							)
						}
					</Authorized>
				))}
		</Tags>
	);
}
