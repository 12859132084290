import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom-pinned-version-6';
import styled from 'styled-components';

import { primaryDriveBlue } from '../colors';
import PasswordInput from '../components/PasswordInput';
import SpinLoader from '../components/spin-loader';
import { trackEvent } from '../utils/analytics';
import type { EventName, ViewType } from '../utils/analytics';
import { put } from '../utils/api';
import useAbortSignal from '../utils/hooks/use-abort-signal';
import '../components/ChangePasswordForm.scss';

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
`;

const BlueLink = styled(Link)`
	color: ${primaryDriveBlue.string()};
`;

interface State {
	error: null | string;
	newPassword: string;
	submitted: boolean;
	submitting: boolean;
	validPassword: boolean;
}

interface Props {
	buttonText: string;
	eventName: EventName;
	hideLoginLink?: boolean;
	token: string;
	username: string;
	viewType: ViewType;
}

function ConfirmForm({
	buttonText,
	eventName,
	hideLoginLink,
	token,
	username,
	viewType,
}: Props): JSX.Element {
	const newPasswordConfirmation = useRef<HTMLInputElement>(null);
	const signal = useAbortSignal();
	const [state, setState] = useState<State>({
		error: null,
		newPassword: '',
		submitted: false,
		submitting: false,
		validPassword: false,
	});
	const handleNewPasswordChange = useCallback(
		(value: string, valid: boolean): void => {
			setState((currState) => ({
				...currState,
				newPassword: value,
				validPassword: valid,
			}));
		},
		[],
	);

	const handleSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			const passwordConfirmation =
				newPasswordConfirmation.current?.value ?? '';

			if (
				state.newPassword.trim().length < 1
				|| passwordConfirmation.trim().length < 1
			) {
				setState((currState) => ({
					...currState,
					error: 'A required field has been left empty.',
				}));
			} else if (
				state.newPassword.trim() !== passwordConfirmation.trim()
			) {
				setState((currState) => ({
					...currState,
					error: 'Passwords do not match.',
				}));
			} else if (!state.validPassword) {
				setState((currState) => ({
					...currState,
					error: 'Password not strong enough.',
				}));
			} else {
				setState((currState) => ({ ...currState, submitting: true }));
				// This tracks: anytime a user enters/submits a new password on the
				// Change & Confirm New Password page
				trackEvent(eventName, 'password-reset', viewType, {
					'Referring User': username,
				});
				put('/auth/password/change', {
					signal,
					body: { username, token, newPassword: state.newPassword },
				})
					.then(() => {
						setState((currState) => ({
							...currState,
							submitting: false,
							submitted: true,
						}));
					})
					.catch(() => {
						setState((currState) => ({
							...currState,
							submitting: false,
							submitted: false,
							error: 'An error has occurred, please try again.',
						}));
					});
			}
		},
		[
			newPasswordConfirmation,
			state.newPassword,
			state.validPassword,
			username,
			token,
			signal,
			eventName,
			viewType,
		],
	);

	if (state.submitting) {
		return (
			<Container>
				<SpinLoader />
			</Container>
		);
	}

	if (state.submitted) {
		return (
			<Container>
				<p>
					Your password has been changed.
					<br />
					Please login with your new password{' '}
					<BlueLink to="/">here</BlueLink>.
				</p>
			</Container>
		);
	}

	return (
		<form className="ChangePasswordForm Login-form" onSubmit={handleSubmit}>
			<div className="Form-container">
				{state.error && (
					<p className="Login-message Login-error">{state.error}</p>
				)}

				<PasswordInput
					minScore={1}
					onChange={handleNewPasswordChange}
					className="passwordContainer"
					placeholder="New password"
					toggleMask={false}
					value={state.newPassword}
				/>
				<input
					className="Account-change-password-input"
					placeholder="Confirm new password"
					ref={newPasswordConfirmation}
					type="password"
				/>
				<input
					className="Login-form-submit"
					disabled={false}
					type="submit"
					value={buttonText}
				/>

				{hideLoginLink && (
					<p>
						<Link to="/login">Back to login</Link>
					</p>
				)}
			</div>
		</form>
	);
}

export default ConfirmForm;
