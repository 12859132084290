import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	type NavigateOptions,
	useLocation,
	useNavigate,
} from 'react-router-dom-pinned-version-6';

import useStableRef from '../utils/hooks/use-stable-ref';
import ImmutableURLSearchParams from '../utils/immutable-url-search-params';

const defaultNavigationOptions = {
	replace: true,
};

const UPDATERS: Array<
	(params: ImmutableURLSearchParams) => ImmutableURLSearchParams
> = [];

/**
 * Provide the same interface as `useImmutableSearchParams` but with a stable
 * reference to our setter function, like `useState`.
 */
export default function useStableImmutableSearchParams(
	navigationOptions: NavigateOptions = defaultNavigationOptions,
) {
	useStableRef(
		navigationOptions,
		'useStableImmutableSearchParams(navigationOptions)',
	);

	useStableRef(UPDATERS, 'useStableImmutableSearchParams().UPDATERS');

	const [useEffectTrigger, triggerUseEffect] = useState(0);

	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = useMemo(
		() => new ImmutableURLSearchParams(location.search),
		[location.search],
	);

	useEffect(() => {
		const before = new ImmutableURLSearchParams(location.search);
		const after = UPDATERS.reduce(
			(params, updater) => updater(params),
			before,
		);

		if (!after.equals(before)) {
			navigate({ search: after.toString() }, navigationOptions);
		}

		// https://stackoverflow.com/a/1232046
		UPDATERS.length = 0;
	}, [useEffectTrigger, location.search, navigate, navigationOptions]);

	const setSearchParams = useCallback(
		(
			updater: (
				params: ImmutableURLSearchParams,
			) => ImmutableURLSearchParams,
		) => {
			UPDATERS.push(updater);
			triggerUseEffect((current) =>
				current === Number.MAX_SAFE_INTEGER ? 0 : current + 1,
			);
		},
		[],
	);

	useStableRef(
		setSearchParams,
		'useStableImmutableSearchParams().setSearchParams',
	);

	return [searchParams, setSearchParams] as const;
}
