// https://lexical.dev/docs/getting-started/theming
import { css } from 'styled-components';

import { colors, fonts } from '../../theme';

export const nodeClassMapping = {
	ltr: 'ltr',
	rtl: 'rtl',
	paragraph: 'editor-paragraph',
	quote: 'editor-quote',
	heading: {
		h1: 'editor-heading-h1',
		h2: 'editor-heading-h2',
		h3: 'editor-heading-h3',
		h4: 'editor-heading-h4',
		h5: 'editor-heading-h5',
		h6: 'editor-heading-h6',
	},
	list: {
		nested: {
			listitem: 'editor-nested-listitem',
		},
		ol: 'editor-list-ol',
		ul: 'editor-list-ul',
		listitem: 'editor-listItem',
		listitemChecked: 'editor-listItemChecked',
		listitemUnchecked: 'editor-listItemUnchecked',
	},
	hashtag: 'editor-hashtag',
	image: 'editor-image',
	link: 'editor-link',
	table: 'editor-table',
	tableCell: 'editor-table-Cell',
	tableCellActionButton: 'editor-table-CellActionButton',
	tableCellActionButtonContainer: 'editor-table-CellActionButtonContainer',
	tableCellHeader: 'editor-table-CellHeader',
	tableCellResizer: 'editor-table-CellResizer',
	tableCellSelected: 'editor-table-CellSelected',
	tableRowStriping: 'editor-table-RowStriping',
	tableScrollableWrapper: 'editor-table-ScrollableWrapper',
	tableSelected: 'editor-table-Selected',
	tableSelection: 'editor-table-Selection',
	text: {
		bold: 'editor-textBold',
		code: 'editor-textCode',
		italic: 'editor-textItalic',
		strikethrough: 'editor-textStrikethrough',
		subscript: 'editor-textSubscript',
		superscript: 'editor-textSuperscript',
		underline: 'editor-textUnderline',
		underlineStrikethrough: 'editor-textUnderlineStrikethrough',
	},
	code: 'editor-code',
	codeHighlight: {
		atrule: 'editor-tokenAttr',
		attr: 'editor-tokenAttr',
		boolean: 'editor-tokenProperty',
		builtin: 'editor-tokenSelector',
		cdata: 'editor-tokenComment',
		char: 'editor-tokenSelector',
		class: 'editor-tokenFunction',
		'class-name': 'editor-tokenFunction',
		comment: 'editor-tokenComment',
		constant: 'editor-tokenProperty',
		deleted: 'editor-tokenProperty',
		doctype: 'editor-tokenComment',
		entity: 'editor-tokenOperator',
		function: 'editor-tokenFunction',
		important: 'editor-tokenVariable',
		inserted: 'editor-tokenSelector',
		keyword: 'editor-tokenAttr',
		namespace: 'editor-tokenVariable',
		number: 'editor-tokenProperty',
		operator: 'editor-tokenOperator',
		prolog: 'editor-tokenComment',
		property: 'editor-tokenProperty',
		punctuation: 'editor-tokenPunctuation',
		regex: 'editor-tokenVariable',
		selector: 'editor-tokenSelector',
		string: 'editor-tokenSelector',
		symbol: 'editor-tokenProperty',
		tag: 'editor-tokenProperty',
		url: 'editor-tokenOperator',
		variable: 'editor-tokenVariable',
	},
};

const themeStyleRules = css`
	.${nodeClassMapping.text.bold} {
		font-weight: 600;
	}
	.${nodeClassMapping.text.italic} {
		font-style: italic;
	}
	.${nodeClassMapping.text.underline} {
		text-decoration: underline;
	}
	.${nodeClassMapping.text.strikethrough} {
		text-decoration: line-through;
	}
	.${nodeClassMapping.text.underlineStrikethrough} {
		text-decoration: underline line-through;
	}
	.${nodeClassMapping.heading.h1} {
		${fonts.h3.productive};
	}
	.${nodeClassMapping.heading.h2} {
		${fonts.h4.productive};
	}
	.${nodeClassMapping.heading.h3},
		.${nodeClassMapping.heading.h4},
		.${nodeClassMapping.heading.h5},
		.${nodeClassMapping.heading.h6} {
		${fonts.h5.productive};
	}

	.${nodeClassMapping.paragraph} {
		margin: 4px 0;
	}

	.${nodeClassMapping.quote} {
		border-left: 2px solid ${colors.border.subtle};
		color: ${colors.text.secondary};
		margin-left: 10px;
		margin: 0;
		padding: 2px 0 2px 8px;
	}

	.${nodeClassMapping.link} {
		color: ${colors.text.interactive};
		&:hover {
			cursor: pointer;
		}
	}

	.${nodeClassMapping.list.ul} {
		list-style-type: disc;
		padding: 0;
		margin: 4px 0;
	}

	.${nodeClassMapping.list.ol} {
		padding: 0;
		margin: 4px 0;
	}

	.${nodeClassMapping.list.listitem} {
		margin: 0 16px;
	}

	.${nodeClassMapping.list.nested.listitem} {
		list-style-type: none;
	}

	.${nodeClassMapping.tableScrollableWrapper} {
		overflow-x: auto;
		margin: 0px 25px 30px 0px;

		.${nodeClassMapping.table} {
			/* Remove the table's margin and put it on the wrapper */
			margin: 0;
		}
	}

	.${nodeClassMapping.table} {
		border-collapse: collapse;
		border-spacing: 0;
		margin: 10px 0px 20px 0px;
		overflow-x: scroll;
		overflow-y: scroll;
		table-layout: fixed;
		width: 100%;
	}

	.${nodeClassMapping.tableSelection} {
		*::selection {
			background-color: transparent;
		}
	}

	.${nodeClassMapping.tableSelected} {
		outline: 2px solid rgb(60, 132, 244);
	}

	.${nodeClassMapping.tableCell} {
		border: 1px solid ${colors.border.active};
		outline: none;
		overflow: auto;
		padding: 6px 8px;
		position: relative;
		text-align: start;
		vertical-align: top;
		width: 75px;
	}

	/*
	  A firefox workaround to allow scrolling of overflowing table cell
	  ref: https://bugzilla.mozilla.org/show_bug.cgi?id=1904159
	*/
	.${nodeClassMapping.tableCell} > * {
		overflow: inherit;
	}

	.${nodeClassMapping.tableCellResizer} {
		cursor: ew-resize;
		height: 100%;
		position: absolute;
		right: -4px;
		top: 0;
		width: 8px;
		z-index: 10;
	}

	.${nodeClassMapping.tableCellHeader} {
		text-align: start;
	}

	.${nodeClassMapping.tableCellSelected} {
		caret-color: transparent;
	}

	.${nodeClassMapping.tableCellSelected}::after {
		background-color: highlight;
		bottom: 0;
		content: '';
		left: 0;
		mix-blend-mode: multiply;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0;
	}
`;
export default themeStyleRules;

// Update this string to test our styling with any node type we allow
export const testText = `
# Heading 1
## Heading 2
### Heading 3
#### Heading 4
##### Heading 5
###### Heading 6

> Single line quote

Some separator text

> Multi line quote
> Multi line quote

- Unordered list item 1
- Unordered list item 2
    - More nesting
        - Even more nesting
- Unordered list item 3

1. Ordered list item 1
    1. Nested item!
2. Ordered list item 2
3. Ordered list item 3

**Bold text**
_italic text via underscore_
*italic text via asterisk*
~~striken-through text~~
`;
