import React from 'react';
import { Link } from 'react-router-dom-pinned-version-6';
import styled from 'styled-components';

import { useUser } from '../authentication';
import { Menu } from '../components/menus';
import { colors, fonts } from '../theme';
import { LightbulbFlashIcon } from '../theme/icons/devices';
import { PersonIcon } from '../theme/icons/human';
import { OverlapIcon } from '../theme/icons/system';
import { BuildingIcon } from '../theme/icons/travel';
import ImmutableURLSearchParams from '../utils/immutable-url-search-params';

import { ENTITY_TYPE_PARAM_KEY } from './use-entity-type';
import { SEARCH_QUERY_PARAM_KEY } from './use-search-query';
import { SEARCH_QUERY_TYPE_PARAM_KEY } from './use-search-query-type';

import { path as searchPath, showNewSearch } from './';

function useSearchLinks(query: string | null) {
	let baseParams = new ImmutableURLSearchParams();

	if (query) {
		baseParams = baseParams.set(SEARCH_QUERY_PARAM_KEY, query);
	}

	const companyKeywordParams = baseParams
		.set(SEARCH_QUERY_TYPE_PARAM_KEY, 'keyword')
		.set(ENTITY_TYPE_PARAM_KEY, 'companies');
	const companyKeywordLink = `/${searchPath}?${companyKeywordParams.toString()}`;

	const conceptualParams = baseParams.set(
		SEARCH_QUERY_TYPE_PARAM_KEY,
		'conceptual',
	);
	const conceptualLink = `/${searchPath}?${conceptualParams.toString()}`;

	const peopleKeywordParams = baseParams
		.set(SEARCH_QUERY_TYPE_PARAM_KEY, 'keyword')
		.set(ENTITY_TYPE_PARAM_KEY, 'people');
	const peopleKeywordLink = `/${searchPath}?${peopleKeywordParams.toString()}`;

	const similarCompaniesParams = baseParams.set(
		SEARCH_QUERY_TYPE_PARAM_KEY,
		'similar_companies',
	);
	const similarCompaniesLink = `/${searchPath}?${similarCompaniesParams.toString()}`;

	return {
		companyKeywordLink,
		conceptualLink,
		peopleKeywordLink,
		similarCompaniesLink,
	};
}

const MenuItem = styled(Link)`
	${fonts.paragraph.paragraph}
	color: ${colors.text.secondary};
	display: flex;
	gap: 4px;
	padding: 6px 8px;

	&:hover {
		background: ${colors.layer.hover};
		color: ${colors.text.secondaryHover};
		cursor: pointer;
	}

	svg {
		height: 16px;
		width: 16px;
	}
`;

interface Props {
	className?: string;
	query: string | null;
}

export default function NagivationHeaderButton({ className, query }: Props) {
	const user = useUser();
	const showAdvancedSearch = showNewSearch(user);
	const links = useSearchLinks(query);

	if (!showAdvancedSearch) {
		return null;
	}

	return (
		<div className={className}>
			<Menu
				buttonMode="dark"
				buttonVariant="primary"
				label="Advanced"
				placement="bottom left"
			>
				<MenuItem to={links.companyKeywordLink}>
					<BuildingIcon />
					Companies
				</MenuItem>
				<MenuItem to={links.peopleKeywordLink}>
					<PersonIcon />
					People
				</MenuItem>
				<MenuItem to={links.conceptualLink}>
					<LightbulbFlashIcon />
					Conceptual Search
				</MenuItem>
				<MenuItem to={links.similarCompaniesLink}>
					<OverlapIcon />
					Similar Companies
				</MenuItem>
			</Menu>
		</div>
	);
}
