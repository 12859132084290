import React from 'react';
import { Link } from 'react-router-dom-pinned-version-6';
import styled from 'styled-components';

import { colors } from '../theme';
import Chip from '../theme/components/chip';
import { SearchSparkleIcon } from '../theme/icons/system';

const StyledChip = styled(Chip)`
	&:hover {
		background: ${colors.data.indigo.layerHover};
		color: ${colors.data.indigo.textSecondaryHover};
	}
`;

export default function BetaChip({ className }: { className?: string }) {
	return (
		<Link to="/new-search">
			<StyledChip
				className={className}
				background={colors.data.indigo.layerSubtle}
				color={colors.data.indigo.textSecondary}
				icon={<SearchSparkleIcon />}
				label="Beta Search"
			/>
		</Link>
	);
}
