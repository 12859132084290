import React from 'react';
import { Route, Routes } from 'react-router-dom-pinned-version-6';

import './Login.scss';
import Confirm from '../password/confirm';
import Reset from '../password/reset';

const PasswordReset = (): JSX.Element => (
	<div className="Login">
		<Routes>
			<Route path="reset" element={<Reset />} />
			<Route path="confirm/:username/:token" element={<Confirm />} />
		</Routes>
	</div>
);

export default PasswordReset;

export const routes = {
	path: 'password',
	element: <PasswordReset />,
	children: [{ path: '*', element: <PasswordReset /> }],
};
