import moment from 'moment';
import numeral from 'numeral';
import React, { type ComponentType, useMemo } from 'react';
import styled from 'styled-components';

import * as colors from '../colors';
import {
	TopLevelDetail,
	TopLevelNumber,
	TopLevelStat,
	TopLevelTable,
	TopLevelTableCell,
	TopLevelTableHeader,
	TopLevelWrapper,
} from '../components/top-level-detail.js';
import Download from '../components/watchlists/download';
import { OPTIONS as NotesContactViaOptions } from '../notes/contacted-dropdown';
import Table, {
	downloadTable,
	getGenericRowKey,
	type IColumn,
	sortDirections,
	type TableProps,
	useScrollHistory,
	useSearchHistory,
	useSortHistory,
} from '../table';
import CheckboxColumn from '../table/columns/checkbox';
import DateColumn from '../table/columns/date';
import ImageColumn from '../table/columns/image';
import NameColumn from '../table/columns/name';
import NumericColumn from '../table/columns/numeric';
import SelectColumn from '../table/columns/select';
import TextColumn from '../table/columns/text';
import { trackEvent } from '../utils/analytics';
import DefaultMap from '../utils/default-map';
import useLocalStorage from '../utils/hooks/use-local-storage';

import FinancialCommitmentGraph from './financial-commitment-graph';
import LPCurrencyColumn from './lpcurrency';
import PointOfContactColumn, {
	usePointOfContactData,
} from './point-of-contact-column';
import {
	probabilities,
	type Probability,
	probabilityOptions,
	weight,
} from './probability';
import ProbabilityColumn, {
	ExcludeLowProbabilityInvestorsContext,
} from './probability-column';
import { DrivePointOfContactOption, type LPData } from './types';
import WeightedCurrencyColumn from './weighted-currency-column';

export function formatMillions(value: number): string {
	return `$${numeral(value / 1e6).format('0,0.0')}M`;
}

const StyledTable = styled(Table as ComponentType<TableProps<LPData>>)`
	th {
		text-align: left;
	}

	.HerbieTable {
		&__body {
			background-color: white;

			&:nth-of-type(even) {
				background-color: ${colors.tableRowBlue.string()};
			}
		}

		&__cell {
			border-right: 1px solid #e6e8eb;

			&:nth-of-type(7) {
				min-width: 40ch;
				div {
					min-width: 40ch;
				}
			}
		}

		&__foot {
			background-color: #f4f6f9;
		}

		&__footer {
			border-right: 1px solid #e2e4e7;
			font-weight: bold;
			padding: 10px 10px;
			white-space: nowrap;
		}

		&__head {
			background-color: #eaecef;
		}

		&__header {
			border-right: 1px solid #dee0e3;
			padding: 10px 10px;
			white-space: nowrap;
		}

		&__table {
			border-top: 1px solid #dee0e3;
		}
	}
`;

const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;
`;

const Header = styled.header`
	align-items: center;
	background-color: #eaecef;
	display: flex;
	flex-direction: row;
	flex-shrink: 0;
	height: 74px;
	justify-content: space-between;
	padding: 10px;
`;

const HeaderLeft = styled.div`
	align-items: baseline;
	display: flex;
	flex-direction: row;
	height: 100%;
	padding: 0 10px;
`;

const HeaderRight = styled.div`
	align-items: center;
	display: flex;
	padding: 0 10px;
`;

const Search = styled.input.attrs({
	type: 'text',
})`
	font-size: inherit;
	max-width: 300px;
	border: none;
	border-radius: 3px;
	padding: 8px;
`;

const Title = styled.h3`
	align-self: flex-end;
	margin-bottom: 6px;
`;

const advisorFoFOptions = [
	'',
	'Abbott Capital',
	'Aberdeen',
	'Acansa',
	'Adveq',
	'Agility',
	'Aksia (TorreyCove)',
	'Albourne',
	'Alesco Advisors',
	'Alex Brown',
	'Allan Biller & Associates',
	'AndCo',
	'Angeles Investment Advisors',
	'Aon',
	'Asset Consulting Group (ACG)',
	'Balentine',
	'Berman/Crescent Advisors',
	'Blackrock',
	'BMO Family Office',
	'Brown Advisory',
	'Buck Consultants',
	'Callan',
	'Cambridge',
	'Canterbury Consulting',
	'CapTRUST',
	'Cardinal Investment Advisors',
	'Cerity Partners',
	'Clearstead',
	'Cliffwater',
	'Colony Group/New Providence',
	'Commonfund',
	'Connaught',
	'Cook Street Consulting',
	'CornerStone Partners',
	'Crewcial Partners',
	'DeMarche Associates',
	'Disciplina Capital Management',
	'East End Advisors',
	'Ehrenkranz',
	'Fiduciary Counseling',
	'Fiducient Advisors',
	'Franklin Park Associates',
	'Fund Evaluation Group (FEG)',
	'Gallagher Fiduciary Advisors',
	'GCM Grosvenor',
	'Gerber Taylor Associates',
	'Glenmede',
	'Global Endowment',
	'Gloucester',
	'Golden Bell Partners',
	'Graysstone',
	'Greycourt',
	'Hall Capital Partners',
	'Hamilton Lane',
	'HarbourVest',
	'Highland Associates',
	'Hirtle, Callaghan & Co.',
	'Horsley Bridge',
	'ICG Advisors',
	'Investure',
	'Knightsbridge',
	'LCG',
	'Makena',
	'Marquette Associates',
	'Meketa',
	'Mercer',
	'Monticello',
	'Morgan Creek',
	'NEPC',
	'Neuberger Berman',
	'Northern Trust',
	'Oxford Financial Group',
	'Partners Capital',
	'Partners Group',
	'Pathsone',
	'Pathway Capital',
	'Pensionmark',
	'Plante Moran',
	'Portfolio Advisors',
	'Prime Bucholz',
	'R.W. Baird, Inc.',
	'Raymond James',
	'Rocaton Investment Advisors',
	'Russell Investments',
	'RVK',
	'SCS Financial',
	'Segal Marco Advisors',
	'Seven Bridges Advisors',
	'Simon Quick Advisors',
	'Slocum',
	'StateStreet ',
	'Stepstone/Greenspring',
	'Strategic Investment Group (SIG)',
	'Summit Rock Advisors',
	'SVB',
	'Tiff',
	'Townsend Group',
	'TrueBridge',
	'Truist',
	'UBS',
	'Veritable',
	'Verus',
	'Willis Towers Watson (WTW)',
	'Wilshire',
	'Windmark Investment Partners',
] as const;

const typeOptions = [
	'',
	'Advisor/Consultant',
	'Corporate Pension Fund',
	'Corporate Treasury',
	'Endowment',
	'Foundation',
	'Fund of Funds',
	'Insurance',
	'Multi Family Office (MFO)',
	'Outsourced Chief Investment Officer (OCIO)',
	'Private Investor/HNW',
	'Public Pension Fund',
	'PWM Platform',
	'Single Family Office (SFO)',
	'Sovereign Wealth Fund',
] as const;

const rsvpOptions = ['', 'Invited', 'Accepted', 'Declined'] as const;

const regionOptions = Object.fromEntries(
	[
		'',
		'Other',
		'--US--',
		'Alaska',
		'Atlanta',
		'Austin',
		'Boston',
		'Chicago',
		'Cincinnati',
		'Columbus',
		'Connecticut',
		'Dallas',
		'Delaware',
		'Denver',
		'Houston',
		'Indiana',
		'Louisiana',
		'Michigan',
		'Minneapolis ',
		'New England (excl. NY & Boston)',
		'New Jersey',
		'New Mexico',
		'New York',
		'NorCal',
		'North Carolina',
		'Pennsylvania',
		'Portland',
		'Rhode Island',
		'Salt Lake City',
		'Seattle',
		'SoCal',
		'St. Louis',
		'Tennessee',
		'Washington, D.C.',
		'Wisconsin',
		'--Canada--',
		'Montreal',
		'Toronto',
		'--International--',
		'Abu Dhabi',
		'Asia',
		'Copenhagen',
		'Dubai',
		'Geneva',
		'London',
		'Middle East',
		'South America',
		'Stockholm',
	].map((region) => [region, region]),
);
const regionOptionCategories = [
	'--US--',
	'--Canada--',
	'--International--',
] as const;

function formatCurrency(amount: number | null) {
	return amount === null ? '' : `$${(amount / 1e6).toFixed(1)}M`;
}

function handleMouseDownEvent(row: LPData) {
	trackEvent('Open Profile', 'lp-fundraising-view', 'lp-fundraising', {
		target_profile_type: row.lp.type,
	});
}

function buildColumns(
	drivePointOfContactOptions: ReadonlyArray<DrivePointOfContactOption>,
) {
	return [
		new ImageColumn({
			select: (row) => ({
				alt: row.lp.name,
				href: `/${row.lp.type}/${row.lp.id}`,
				src: row.lp.logo_url,
			}),
			handleMouseDownEvent,
		}),
		new NameColumn({
			select: (row) => ({
				href: `/${row.lp.type}/${row.lp.id}`,
				name: row.lp.name,
			}),
			handleMouseDownEvent,
		}),
		new SelectColumn({
			name: 'Type',
			options: Object.fromEntries(
				typeOptions.map((option) => [option, option]),
			),
			select: (row) => row.lp_type,
			update: (value) => ({ lp_type: value }),
		}),
		new SelectColumn<LPData, LPData['region']>({
			disabledOptions: regionOptionCategories,
			name: 'Travel Region',
			options: regionOptions,
			select: (row) => row.region,
			update: (value) => ({ region: value }),
		}),
		new TextColumn({
			name: 'Location',
			select: (row) => row.location || row.lp.locations,
			update: (value) => ({ location: value }),
		}),
		new PointOfContactColumn({
			name: 'Point of Contact',
			drivePointOfContactOptions,
		}),
		new TextColumn({
			name: 'Pipeline Status',
			select: (row) => row.next_step,
			update: (value) => ({ next_step: value }),
		}),
		new TextColumn({
			name: 'Outreach Status',
			select: (row) => row.outreach_next_step,
			update: (value) => ({ outreach_next_step: value }),
		}),
		new SelectColumn({
			name: 'Last Outreach Method',
			options: {
				'': '',
				...Object.fromEntries(
					NotesContactViaOptions.toSorted().map((option) => [
						option,
						option,
					]),
				),
			},
			select: (row) => row.last_contact_via ?? '',
			update: (value) => ({ last_contact_via: value }),
		}),
		new DateColumn({
			format: (date) => date.fromNow(),
			name: 'Last IR Contact',
			select: (row) =>
				row.last_contact_with_investor_relations_team
					? moment(row.last_contact_with_investor_relations_team)
					: null,
		}),
		new NumericColumn({
			format: (value) => (value ? value.toString() : ''),
			name: 'Calls & Meetings',
			select: (row) => row.recent_calls_and_meetings,
		}),
		new SelectColumn({
			name: 'Advisor',
			options: Object.fromEntries(
				advisorFoFOptions.map((option) => [option, option]),
			),
			select: (row) => row.advisor_or_fund_of_funds,
			update: (value) => ({ advisor_or_fund_of_funds: value }),
		}),
		new WeightedCurrencyColumn({
			name: 'Drive25 $',
			select: (row) => ({
				amount: row.potential_commitment_to_drive_25,
				probability: row.probability_future,
			}),
			update: (value) => ({ potential_commitment_to_drive_25: value }),
			weight,
		}),
		new ProbabilityColumn({
			name: 'Probability',
			select: (row) => `${row.probability_future}`,
			update: (value) => ({
				probability_future: parseInt(value, 10) as Probability,
			}),
		}),
		new LPCurrencyColumn({
			format: formatCurrency,
			name: 'Total $ Invested',
			select: (row) => row.total_committed_dollars,
		}),
		new SelectColumn({
			name: "March '24 AGM",
			options: Object.fromEntries(
				rsvpOptions.map((option) => [option, option]),
			),
			select: (row) => row.march_2024_annual_meeting_rsvp,
			update: (value) => ({ march_2024_annual_meeting_rsvp: value }),
		}),
		new SelectColumn({
			name: "March '24 Onsite DD",
			options: Object.fromEntries(
				rsvpOptions.map((option) => [option, option]),
			),
			select: (row) => row.march_2024_onsite_due_diligence_rsvp,
			update: (value) => ({
				march_2024_onsite_due_diligence_rsvp: value,
			}),
		}),
		new CheckboxColumn({
			name: 'Needs Follow-Up',
			select: (row) => row.follow_up_required,
			update: (value) => ({ follow_up_required: value }),
		}),
		new CheckboxColumn({
			name: 'Has Data Room',
			select: (row) => !!row.box_folder_id,
		}),
		new CheckboxColumn({
			name: 'Data Room Access',
			select: (row) => !!row.box_folder_id && row.data_room_access,
			update: (value) => ({ data_room_access: value }),
		}),
		new CheckboxColumn({
			name: 'NDA Required',
			select: (row) => row.enable_click_through_nda,
			update: (value) => ({ enable_click_through_nda: value }),
		}),
		new NumericColumn({
			format: (value) => (value ? value.toString() : ''),
			name: 'Active Users',
			select: (row) => row.active_users,
		}),
		new LPCurrencyColumn({
			format: formatCurrency,
			name: 'Fund I $',
			select: (row) => row.amount_invested_in_fund_one,
		}),
		new LPCurrencyColumn({
			format: formatCurrency,
			name: 'Fund II $',
			select: (row) => row.amount_invested_in_fund_two,
		}),
		new LPCurrencyColumn({
			format: formatCurrency,
			name: 'Fund III $',
			select: (row) => row.potential_commitment_to_fund_three,
		}),
		new LPCurrencyColumn({
			format: formatCurrency,
			name: 'Overdrive I $',
			select: (row) => row.potential_commitment_to_growth_fund_one,
		}),
		new LPCurrencyColumn({
			format: formatCurrency,
			name: 'Fund IV $',
			select: (row) => row.potential_commitment_to_fund_four,
			update: (value) => ({
				potential_commitment_to_fund_four: value,
			}),
		}),
		new LPCurrencyColumn({
			format: formatCurrency,
			name: 'Overdrive II $',
			select: (row) => row.potential_commitment_to_growth_fund_two,
			update: (value) => ({
				potential_commitment_to_growth_fund_two: value,
			}),
		}),
		new LPCurrencyColumn({
			format: formatCurrency,
			name: 'Drive 22 $',
			select: (row) => {
				const total =
					(row.potential_commitment_to_fund_four ?? 0)
					+ (row.potential_commitment_to_growth_fund_two ?? 0);
				return total === 0 ? null : total;
			},
		}),
		new LPCurrencyColumn({
			format: formatCurrency,
			name: 'AUM',
			select: (row) => row.assets_under_management,
			update: (value) => ({
				assets_under_management: value,
			}),
		}),
		new TextColumn({
			name: 'Key Person',
			select: (row) => row.main_contact_name,
			update: (value) => ({ main_contact_name: value }),
		}),
		new TextColumn({
			name: 'Key Person Email',
			select: (row) => row.main_contact_email,
			update: (value) => ({ main_contact_email: value }),
		}),
	] satisfies Array<IColumn<LPData>>;
}

const weightedPipelineProbabilities = [
	probabilities.ONE,
	probabilities.TWO,
	probabilities.THREE,
	probabilities.FOUR,
];

type Props = {
	data: Array<LPData>;
	onChange: (
		row: LPData,
		patch: Partial<LPData>,
		signal: AbortSignal,
	) => Promise<LPData>;
};

export default function LPTable({ data, ...props }: Props) {
	const [sort, onSort] = useSortHistory({
		direction: sortDirections.ascending,
		index: 1,
	});
	const [scrollX, scrollY, onScroll] = useScrollHistory();
	const [search, onSearch] = useSearchHistory();
	const [excludeLowProbabilityLP, setExcludeLowProbabilityLP] =
		useLocalStorage('excludeLowProbabilityLP', true);
	const excludeLowProbabilityLPState = useMemo(
		() => [excludeLowProbabilityLP, setExcludeLowProbabilityLP] as const,
		[excludeLowProbabilityLP, setExcludeLowProbabilityLP],
	);
	const {
		options: drivePointOfContactOptions,
		isSelected: isDrivePointOfContactSelected,
	} = usePointOfContactData();
	const columns = useMemo(
		() => buildColumns(drivePointOfContactOptions),
		[drivePointOfContactOptions],
	);

	const rows = React.useMemo(() => {
		const filteredByProbability = excludeLowProbabilityLP
			? data.filter((row) => row.probability_future !== 5)
			: data;
		const filteredByPointOfContact = filteredByProbability.filter(
			({ drive_point_of_contact }) =>
				isDrivePointOfContactSelected(drive_point_of_contact),
		);
		return filteredByPointOfContact;
	}, [data, excludeLowProbabilityLP, isDrivePointOfContactSelected]);

	// To avoid 6 probability levels * 2 funds iterations of `data` in inline
	// `reduce()`s per render, iterate once in a memoized calculation here.
	const pipelineByProbability = React.useMemo(() => {
		const currentFundraise = new DefaultMap<Probability, number>(() => 0);
		// Iterate `data` rather than `rows` so that we always include 5s.
		for (const row of data) {
			if (isDrivePointOfContactSelected(row.drive_point_of_contact)) {
				currentFundraise.set(
					row.probability_future,
					currentFundraise.get(row.probability_future)
						+ (row.potential_commitment_to_drive_25 ?? 0),
				);
			}
		}
		return currentFundraise;
	}, [data, isDrivePointOfContactSelected]);

	const pipelineByPointOfContact = React.useMemo(() => {
		const currentFundraise = new DefaultMap<string | null, number>(() => 0);
		for (const row of data) {
			if (!(excludeLowProbabilityLP && row.probability_future === 5)) {
				currentFundraise.set(
					row.drive_point_of_contact || '',
					currentFundraise.get(row.drive_point_of_contact || '')
						+ (row.follow_up_required ? 1 : 0),
				);
			}
		}
		return currentFundraise;
	}, [data, excludeLowProbabilityLP]);

	return (
		<Container>
			<Header>
				<HeaderLeft>
					<Title>LP Dashboard</Title>
				</HeaderLeft>
				<HeaderRight>
					<Search
						defaultValue={search}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>,
						) => {
							onSearch(event.target.value);
						}}
						placeholder="Search"
					/>
					<Download
						className="WatchList-download"
						onDownload={() =>
							downloadTable(
								columns,
								rows === null ? [] : rows,
								'LP Dashboard',
								search,
								sort,
							)
						}
					/>
				</HeaderRight>
			</Header>

			<TopLevelWrapper>
				<TopLevelStat>
					<TopLevelNumber>
						{formatMillions(
							pipelineByProbability.get(
								probabilities.ZERO_POINT_FIVE,
							),
						)}
					</TopLevelNumber>
					<TopLevelDetail>Committed</TopLevelDetail>
				</TopLevelStat>
				<FinancialCommitmentGraph />
				<TopLevelStat>
					<TopLevelNumber>
						{formatMillions(
							weightedPipelineProbabilities.reduce(
								(total, probability) =>
									total
									+ weight(
										pipelineByProbability.get(probability),
										probability,
									),
								0,
							),
						)}
					</TopLevelNumber>
					<TopLevelTable>
						<tbody>
							<tr>
								{weightedPipelineProbabilities.map(
									(probability) => (
										<TopLevelTableCell key={probability}>
											{formatMillions(
												weight(
													pipelineByProbability.get(
														probability,
													),
													probability,
												),
											)}
										</TopLevelTableCell>
									),
								)}
							</tr>
							<tr>
								{weightedPipelineProbabilities.map(
									(probability) => (
										<TopLevelTableHeader key={probability}>
											{probabilityOptions.get(
												probability,
											)}
										</TopLevelTableHeader>
									),
								)}
							</tr>
						</tbody>
					</TopLevelTable>
					<TopLevelDetail>Weighted Pipeline</TopLevelDetail>
				</TopLevelStat>
				<TopLevelStat>
					{drivePointOfContactOptions.some(
						(pointOfContact) =>
							pipelineByPointOfContact.get(
								pointOfContact.username,
							) > 0,
					) ? (
						<TopLevelTable>
							<tbody>
								<tr>
									{pipelineByPointOfContact.get('') > 0 && (
										<TopLevelTableCell>
											{pipelineByPointOfContact.get('')}
										</TopLevelTableCell>
									)}
									{drivePointOfContactOptions
										.filter(
											({ username }) =>
												pipelineByPointOfContact.get(
													username,
												) > 0,
										)
										.map(({ username }) => (
											<TopLevelTableCell key={username}>
												{pipelineByPointOfContact.get(
													username,
												)}
											</TopLevelTableCell>
										))}
								</tr>
								<tr>
									{pipelineByPointOfContact.get('') > 0 && (
										<TopLevelTableHeader>
											Nobody
										</TopLevelTableHeader>
									)}
									{drivePointOfContactOptions
										.filter(
											({ username }) =>
												pipelineByPointOfContact.get(
													username,
												) > 0,
										)
										.map(({ first_name, username }) => (
											<TopLevelTableHeader key={username}>
												{first_name}
											</TopLevelTableHeader>
										))}
								</tr>
							</tbody>
						</TopLevelTable>
					) : (
						<TopLevelNumber>0</TopLevelNumber>
					)}
					<TopLevelDetail>Follow-Up</TopLevelDetail>
				</TopLevelStat>
			</TopLevelWrapper>

			<ExcludeLowProbabilityInvestorsContext.Provider
				value={excludeLowProbabilityLPState}
			>
				<StyledTable
					{...props}
					columns={columns}
					data={rows}
					getRowKey={getGenericRowKey}
					initialScrollX={scrollX}
					initialScrollY={scrollY}
					initialSort={sort}
					onScroll={onScroll}
					onSort={onSort}
					search={search}
				/>
			</ExcludeLowProbabilityInvestorsContext.Provider>
		</Container>
	);
}
