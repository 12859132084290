import { DialogContent, DialogOverlay } from '@reach/dialog';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createHorizontalStrength, useDndScrolling } from 'react-dnd-scrolling';
import { useLocation, useNavigate } from 'react-router-dom-pinned-version-6';
import styled from 'styled-components';
import colors from 'tailwindcss/colors';

import HerbieLoader from '../components/herbie-loader';

import { useMovePipelineItem, usePipeline } from './api';
import PipelineStage from './pipeline-stage';
import ProfileCardDragLayer from './profile-card-drag-layer';
import ProfileCardExpanded from './profile-card-expanded';
import type {
	PipelineStageItem,
	StageTransitionDataRule,
	StageTransitionDataValue,
} from './types';

const PipelineContainer = styled.div<{ isPaused: boolean }>`
	background-color: ${colors.slate[50]};
	height: 100%;
	overflow-x: scroll;
	overflow-y: hidden;
	padding: 24px;
	width: 100%;

	display: grid;
	grid-gap: 24px;
	grid-auto-columns: 320px;
	grid-row-template: 100%;
	grid-auto-flow: column;

	${({ isPaused }) => (isPaused ? 'opacity: 0.4;' : '')}
`;

const ExpandedCardOverlay = styled(DialogOverlay)``;
const ExpandedCardContainer = styled(DialogContent)`
	border-radius: 10px;
	box-shadow: 0 0 48px rgba(0, 0, 0, 0.15);
	position: relative;
	width: 800px;
	height: 800px;
	padding: 0 !important;
	overflow: hidden;
`;

interface Props {
	id: number;
	ExpandedCard: React.ComponentType<{
		editable: boolean;
		item: PipelineStageItem;
		onClose: () => void;
		pipelineId: number;
		stageRules: Record<string, Array<StageTransitionDataRule>>;
	}>;
	mapStageTitleToColors?: (title: string) => {
		backgroundColor: string;
		textColor: string;
	};
}

type DraggingState =
	| { name: 'NOT_DRAGGING'; card: null; stageId: null }
	| {
			name: 'EVALUATING_TRANSITION_RULES';
			card: PipelineStageItem;
			stageId: number;
	  };

const horizontalStrength = createHorizontalStrength(200);

function DraggablePipeline({
	id,
	ExpandedCard = ProfileCardExpanded,
	mapStageTitleToColors,
}: Props): JSX.Element {
	const { data: pipeline } = usePipeline(id);
	const [draggingState, setDraggingState] = useState<DraggingState>({
		card: null,
		name: 'NOT_DRAGGING',
		stageId: null,
	});
	const containerRef = useRef<HTMLDivElement | null>(null);
	useDndScrolling(containerRef, {
		horizontalStrength,
		strengthMultiplier: 50,
	});
	const location = useLocation();
	const navigate = useNavigate();

	const handleCardClick = useCallback(
		(item: PipelineStageItem) => {
			navigate({ hash: `#item-${item.id}` }, { replace: true });
		},
		[navigate],
	);

	const handleCardClose = useCallback(() => {
		navigate({ hash: '' }, { replace: true });
	}, [navigate]);

	const moveItem = useMovePipelineItem();
	const handleCardDrop = useCallback(
		(
			item: PipelineStageItem,
			destinationStageId: number,
			transitionData: Array<StageTransitionDataValue>,
		) => {
			setDraggingState({
				name: 'NOT_DRAGGING',
				card: null,
				stageId: null,
			});
			moveItem.mutate({
				destinationStageId,
				item,
				pipelineId: id,
				transitionData,
			});
		},
		[id, moveItem],
	);
	const handleRuleEvaluation = useCallback(
		(card: PipelineStageItem | null, stageId: number) => {
			setDraggingState(
				card === null
					? { name: 'NOT_DRAGGING', card, stageId: null }
					: { name: 'EVALUATING_TRANSITION_RULES', card, stageId },
			);
		},
		[],
	);

	const stages = useMemo(
		() => (pipeline?.stages || []).sort((a, b) => a.sortKey - b.sortKey),
		[pipeline?.stages],
	);
	const stageItems = useMemo(
		() =>
			Object.fromEntries(
				stages.map((stage) => [
					stage.id,
					(pipeline?.items || []).filter(
						(item) => item.currentStageId === stage.id,
					),
				]),
			),
		[stages, pipeline?.items],
	);

	const stageEvaluatingCard = useMemo(
		() =>
			Object.fromEntries(
				stages.map((stage) => [
					stage.id,
					draggingState.name === 'EVALUATING_TRANSITION_RULES'
					&& draggingState.stageId === stage.id
						? draggingState.card
						: null,
				]),
			),
		[draggingState.card, draggingState.name, draggingState.stageId, stages],
	);
	const stageRules = useMemo(
		() =>
			Object.fromEntries(
				(stages || []).map((stage) => [
					stage.id,
					[...stage.rules].sort((a, b) => a.sortKey - b.sortKey),
				]),
			),

		[stages],
	);

	const expandedCard = useMemo(() => {
		const strippedHash = parseInt(location.hash.replace('#item-', ''), 10);
		if (strippedHash) {
			return pipeline?.items.find((itm) => itm.id === strippedHash);
		}
		return null;
	}, [location.hash, pipeline?.items]);

	if (!pipeline) return <HerbieLoader />;

	const userAllowedToDragAndDrop =
		pipeline.accessGrantLevel === 'EDITOR'
		|| pipeline.accessGrantLevel === 'OWNER';

	return (
		<PipelineContainer
			isPaused={draggingState.name === 'EVALUATING_TRANSITION_RULES'}
			ref={containerRef}
		>
			<ProfileCardDragLayer />
			{stages.map((stage) => (
				<PipelineStage
					key={stage.id}
					dragAndDropAllowed={userAllowedToDragAndDrop}
					currentlyEvaluatingCard={stageEvaluatingCard[stage.id]}
					items={stageItems[stage.id]}
					mapStageTitleToColors={mapStageTitleToColors}
					onCardClick={handleCardClick}
					onCardDrop={handleCardDrop}
					onRuleEvaluation={handleRuleEvaluation}
					stage={stage}
				/>
			))}
			{!!expandedCard && (
				<ExpandedCardOverlay isOpen onDismiss={handleCardClose}>
					<ExpandedCardContainer aria-label="Additional Profile Information">
						<ExpandedCard
							editable={pipeline.editable}
							item={expandedCard}
							onClose={handleCardClose}
							pipelineId={id}
							stageRules={stageRules}
						/>
					</ExpandedCardContainer>
				</ExpandedCardOverlay>
			)}
		</PipelineContainer>
	);
}

export default function Pipeline(props: Props): JSX.Element {
	return (
		<DndProvider backend={HTML5Backend}>
			<DraggablePipeline {...props} />
		</DndProvider>
	);
}
