import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom-pinned-version-6';

import './AddCompanyForm.scss';
import { load } from '../actions';
import * as api from '../utils/api';
import cancelablePromise from '../utils/cancelable-promise';
import noop from '../utils/noop';
import reportError from '../utils/sentry';

const mapDispatchToProps = (dispatch, { onCreate, navigate }) => ({
	onCreate: (entity) => {
		dispatch(load(entity));
		if (onCreate) {
			onCreate(entity);
		} else {
			navigate(`/${entity.type}/${entity.id}`);
		}
	},
});

class AddCompanyForm extends Component {
	static displayName = 'AddCompanyForm';

	static propTypes = {
		className: PropTypes.string,
		onCancel: PropTypes.func.isRequired,
		onCreate: PropTypes.func.isRequired,
	};

	static defaultProps = {
		onCancel: noop,
	};

	state = {
		creating: false,
		homepage: '',
		name: '',
	};

	componentWillUnmount() {
		if (this.promise) {
			this.promise.cancel();
		}
	}

	validate({ name }) {
		if (!name) {
			return 'Name is required';
		}

		if (this.refs.homepage) {
			if (!this.refs.homepage.validity.valid) {
				return 'Bad URL format';
			}
		}

		return true;
	}

	handleCancel = () => {
		this.props.onCancel();
	};

	handleHomepageChange = (event) => {
		this.setState({
			homepage: event.target.value,
		});
	};

	handleNameChange = (event) => {
		this.setState({
			name: event.target.value,
		});
	};

	handleSubmit = (event) => {
		event.preventDefault();

		this.setState({
			creating: true,
		});

		this.promise = cancelablePromise(
			api.post('/companies', {
				body: {
					name: this.refs.name.value,
					homepage: this.refs.homepage.value,
				},
			}),
		);

		this.promise
			.then((response) => {
				this.request = null;

				this.setState({
					creating: false,
				});

				return response;
			})
			.then((response) => {
				this.props.onCreate(response);
			})
			.catch((error) => {
				reportError(error);
			});
	};

	render() {
		const valid = this.validate(this.state);
		let label = 'Create';

		if (this.state.creating) {
			label = 'Creating...';
		} else if (valid !== true) {
			label = valid;
		}

		return (
			<form
				className={`AddCompanyForm ${this.props.className || ''}`}
				onSubmit={this.handleSubmit}
			>
				<input
					className="AddCompanyForm-name"
					onChange={this.handleNameChange}
					placeholder="Name"
					ref="name"
					type="text"
					value={this.state.name}
				/>
				<input
					className="AddCompanyForm-homepage"
					onChange={this.handleHomepageChange}
					placeholder="Homepage (optional)"
					ref="homepage"
					type="url"
					value={this.state.homepage}
				/>
				<div className="AddCompanyForm-buttons">
					<input
						className="AddCompanyForm-cancel"
						onClick={this.handleCancel}
						type="button"
						value="Cancel"
					/>
					<input
						className="AddCompanyForm-submit primary"
						disabled={this.state.creating || valid !== true}
						type="submit"
						value={label}
					/>
				</div>
			</form>
		);
	}
}

const ConnectedForm = connect(null, mapDispatchToProps)(AddCompanyForm);
export default function CompanyForm(props) {
	const navigate = useNavigate();
	return <ConnectedForm {...props} navigate={navigate} />;
}
