// @flow

import React from 'react';
import { NavLink } from 'react-router-dom-pinned-version-6';

import './stats-detail-list-item.scss';
import { getRouteForKey } from './chart-utils';

type Props = {
	id: string,
	pictureUrl: string,
	statName: string,
};

const StatsDetailListItem = ({ id, pictureUrl, statName }: Props) => (
	<li>
		<NavLink className="StatsDetailListItem" to={getRouteForKey(id)}>
			<div className="StatsDetailListItem-image">
				<img src={pictureUrl} />
			</div>
			<div className="StatsDetailListItem-label">
				<h4>{statName}</h4>
			</div>
		</NavLink>
	</li>
);

export default StatsDetailListItem;
