// @flow

import omit from 'lodash.omit';
import querystring from 'querystring';
import React, { type AbstractComponent as ReactAbstractComponent } from 'react';
import { connect } from 'react-redux';
import { useSearchParams } from 'react-router-dom-pinned-version-6';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { searchSuccess } from '../actions';
import type { Action } from '../actions';
import type { State as StoreState } from '../reducers';
import { getAlgoliaKey } from '../selectors';
import search from '../utils/algolia';
import cancelablePromise from '../utils/cancelable-promise';
import reportError from '../utils/sentry';

import { deserialize, serialize } from './sync';

type AlgoliaQuery = {
	facets?: string | Array<string>,
	filters?: string | Array<string | Array<string>>,
	maxValuesPerFacet?: number,
	page?: number,
	query?: string,
};

export type HighlightResult = {|
	fullyHighlighted: boolean,
	matchedWords: Array<string>,
	matchLevel: 'none' | 'partial' | 'full',
	value: string,
|};

export type AlgoliaCompanyData = {
	description: string,
	employee_change?: number | null,
	employee_change_one_month?: number | null,
	employee_change_three_months?: number | null,
	employee_change_twelve_months?: number | null,
	employee_count?: number,
	founding?: number,
	funding?: number,
	homepage_url: string,
	id: number,
	industry: Array<string>,
	last_contact_with_drive: string,
	last_fund_raised?: string,
	last_funding_round?: string,
	location: Array<string>,
	logo_url: string,
	name: string,
	type: 'companies',
};

export type AlgoliaCompanyHit = {
	// The highlight data is optional because it is provided by Algolia. When we
	// merge companies, the backend returns the merged company to be swapped in
	// by the search backend while we wait for Algolia to re-index. That object
	// won't have any highlight data.
	_highlightResult?: {|
		data?: {|
			description?: HighlightResult,
			location?: Array<HighlightResult>,
			market_maps?: Array<HighlightResult>,
			name?: HighlightResult,
		|},
		market_maps?: Array<HighlightResult>,
		notes?: Array<HighlightResult>,
		terms: Array<HighlightResult>,
	|},
	category: string,
	data: AlgoliaCompanyData,
	id: number,
	investments?: {
		count: number,
		industries: Array<string>,
		largest: number,
		last_date: number | null,
		locations: Array<string>,
	},
	known_employee_count: number,
	type: 'companies',
};

export type AlgoliaPersonData = {
	best_relationship: {
		best_contact_data: string,
		has_last_contact_date: true,
		last_contact_date: string,
	},
	current_company?: {
		name: string,
		total: number,
	},
	current_location?: string | null,
	first_name: string,
	id: number,
	last_name: string,
	location: Array<string>,
	num_total_emails: number,
	photo_url: string,
	primary_email: null | {
		email: string,
	},
	roles: Array<string>,
	type: 'people',
};

export type AlgoliaPersonHit = {
	// The highlight data is optional because it is provided by Algolia. When we
	// merge people, the backend returns the merged person to be swapped in by
	// the search backend while we wait for Algolia to re-index. That object
	// won't have any highlight data.
	_highlightResult?: {|
		data?: {
			location?: Array<HighlightResult>,
			name?: HighlightResult,
		},
		jobs?: Array<HighlightResult>,
		notes?: Array<HighlightResult>,
		terms?: Array<HighlightResult>,
	|},
	category: string,
	data: AlgoliaPersonData,
	id: number,
	investments?: {
		count: number,
		industries: Array<string>,
		largest: number,
		last_date: number | null,
		locations: Array<string>,
	},
	jobs: Array<string>,
	type: 'people',
};

export type AlgoliaHit = AlgoliaCompanyHit | AlgoliaPersonHit;

export type AlgoliaResponse = {
	exhaustiveFacetsCount: boolean,
	exhaustiveNbHits: boolean,
	facets: {
		[facet: string]: {
			[value: string]: number,
		},
	},
	hits: Array<AlgoliaHit>,
	hitsPerPage: number,
	nbHits: number,
	nbPages: number,
	page: number,
	params: string,
	processingTimeMS: number,
	query: string,
};

export type Filters = {
	// TODO: This is a hack for QuickSearch. We don't have a first-class method
	// to negate a filter, so QuickSearch relies on this getting passed through
	// to the final filters object.
	//
	// I think that, since we wrote this, we added support for minimum filters,
	// so I think we could instead do `'data.num_total_emails': [1, null]` as
	// a first-class filter. Leaving this for now because I only want to add
	// types in this PR without changing behavior.
	'NOT data.num_total_emails'?: '=0',

	page?: number,
	query?: string,
	tags?: Array<string>,
};

export type SearchBehaviorProps = {|
	complete: boolean,
	filters: Filters,
	loading: boolean,
	onChange: (filters: Filters) => void,
	onScroll: () => void,
	paging: boolean,
	query: string,
	resultCount: ?number,
	results: ?Array<AlgoliaHit>,
|};

export function buildAlgoliaQuery(filters: Filters): AlgoliaQuery {
	// If I search for { "query": "c", "tagFilters": ["person"] },
	// Algolia returns ~68k in `nbHits`. For some unknown reason,
	// having Algolia return even a single facet value causes
	// `nbHits` to become correct. This was determined via trial
	// and error, and it makes no more sense to me than it does
	// to you.
	//
	// Note that this does not affect the queries that we do
	// want to return facet values (e.g. a location filter)
	// because those call the search method directly instead of
	// going through `searchBehavior`.
	const queryObject: AlgoliaQuery = {
		facets: 'data.location',
		maxValuesPerFacet: 1,
	};

	if (typeof filters.page === 'number') {
		queryObject.page = filters.page;
	}

	if (typeof filters.query === 'string') {
		queryObject.query = filters.query;
	}

	queryObject.filters = Object.keys(filters)
		.reduce((arr: Array<Array<string>>, key: string) => {
			const filter = filters[key];

			if (key === 'query') {
				// The query goes elsewhere
				return arr;
			} else if (key === 'tags') {
				// We have an array of tag names
				return arr.concat([filter.map((tag) => `_tags:${tag}`)]);
			} else if (Array.isArray(filter)) {
				if (filter.every((el) => typeof el === 'string')) {
					// We have an array of facet names
					return arr.concat([
						filter.map((facet) => `${key}:"${facet}"`),
					]);
				} else {
					// We have a (minimum, maximum) 2-tuple
					if (filter[0] !== null && filter[1] !== null) {
						return arr.concat([
							[`${key}:${filter[0]} TO ${filter[1]}`],
						]);
					} else if (filter[0] !== null) {
						return arr.concat([[`${key}>=${filter[0]}`]]);
					} else if (filter[1] !== null) {
						return arr.concat([[`${key}<=${filter[1]}`]]);
					}
				}
			} else {
				return arr.concat([[`${key}${filter}`]]);
			}

			return arr;
		}, [])
		.map((group) => `(${group.join(' OR ')})`)
		.join(' AND ');

	return queryObject;
}

type Options = {
	defaultFilters?: Filters,
	history?: boolean,
};

type SearchParamsProps = {|
	searchParams: URLSearchParams,
	setSearchParams: (params: URLSearchParams, options: any) => void,
|};

type StateProps = {|
	merged: {
		companies: { [key: string]: AlgoliaHit },
		people: { [key: string]: AlgoliaHit },
	},
	robots: Array<number>,
	searchKey: string,
|};

type DispatchProps = {|
	searchSuccess: (data: AlgoliaResponse) => void,
|};

type WrapWithSearchParamsProps = {|
	...StateProps,
	...DispatchProps,
|};

type Props = {|
	...SearchParamsProps,
	...WrapWithSearchParamsProps,
|};

type State = {
	filters: Filters,
	loading: boolean,
	pageCount: ?number,
	pagesLoaded: ?number,
	paging: boolean,
	resultCount: ?number,
	results: ?Array<AlgoliaHit>,
};

const mapStateToProps = (state: StoreState): StateProps => ({
	merged: state.merged,
	robots: state.robots,
	searchKey: getAlgoliaKey(state),
});

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps =>
	bindActionCreators(
		{
			searchSuccess,
		},
		dispatch,
	);

export default (options?: Options = {}) => {
	return function wrapWithSearchBehavior<Config: { ...SearchBehaviorProps }>(
		ComposedComponent: ReactAbstractComponent<Config>,
	): ReactAbstractComponent<$Diff<Config, SearchBehaviorProps>, mixed> {
		function WrapWithSearchParams(props: WrapWithSearchParamsProps) {
			const [searchParams, setSearchParams] = useSearchParams();
			return (
				<SearchBehavior
					{...props}
					searchParams={searchParams}
					setSearchParams={setSearchParams}
				/>
			);
		}

		class SearchBehavior extends React.Component<Props, State> {
			_searchRequest: ?(Promise<AlgoliaResponse> & {
				cancel: () => void,
			});
			_unlisten: ?() => void;

			state: State = {
				filters:
					options.history && this.props.searchParams.toString()
						? deserialize(
								querystring.parse(
									this.props.searchParams.toString(),
								),
						  )
						: {},
				loading: false,
				pageCount: null,
				pagesLoaded: null,
				paging: false,
				resultCount: null,
				results: null,
			};

			componentDidMount() {
				if (this.state.filters) {
					this.search(this.state.filters);
				}
			}

			componentWillUnmount() {
				this.cancelSearch();
				if (typeof this._unlisten === 'function') {
					this._unlisten();
					delete this._unlisten;
				}
			}

			cancelSearch() {
				if (this._searchRequest) {
					this.setState({
						loading: false,
						paging: false,
					});
					if (this._searchRequest) {
						this._searchRequest.cancel();
						delete this._searchRequest;
					}
				}
			}

			getProperties(
				overrides: $Shape<SearchBehaviorProps> = {},
			): SearchBehaviorProps {
				const duplicates = new Set();
				const results =
					this.state.results
					&& this.state.results
						.map((result) => {
							if (
								this.props.merged[result.type][
									result.id.toString()
								]
							) {
								return this.props.merged[result.type][
									result.id.toString()
								];
							}

							return result;
						})
						.filter((result) => {
							const key = `/${result.type}/${result.id}`;

							if (duplicates.has(key)) {
								return false;
							}

							duplicates.add(key);

							if (this.props.robots.indexOf(result.id) >= 0) {
								return false;
							}

							return true;
						});

				return {
					complete: this.state.pagesLoaded === this.state.pageCount,
					filters: this.state.filters,
					loading: this.state.loading,
					onChange: this.handleChange,
					onScroll: this.handleScroll,
					paging: this.state.paging,
					query: this.state.filters.query || '',
					resultCount: this.state.resultCount,
					results,
					...overrides,
				};
			}

			hasParameters(filters: Filters): boolean {
				return (
					(typeof filters.query === 'string'
						&& filters.query.length >= 1)
					|| Object.keys(filters).filter(
						(key) =>
							![
								'NOT data.num_total_emails',
								'query',
								'tags',
							].includes(key),
					).length >= 1
				);
			}

			page(): void {
				if (this._searchRequest) {
					return;
				}

				this.setState({ paging: true });
				this._searchRequest = cancelablePromise(
					search(this.props.searchKey, {
						page: this.state.pagesLoaded,
						...buildAlgoliaQuery({
							...options.defaultFilters,
							...this.state.filters,
						}),
					}),
				);
				this._searchRequest
					.then((response: AlgoliaResponse) => {
						delete this._searchRequest;

						this.props.searchSuccess(response);

						this.setState((state) => ({
							pageCount: response.nbPages,
							pagesLoaded: response.page + 1,
							paging: false,
							resultCount: response.nbHits,
							// If we're paging, we'll already have results, but the
							// typechecker doesn't know that.
							results: (state.results || []).concat(
								response.hits,
							),
						}));
					})
					.catch((error) => {
						this.setState({ paging: false });
						reportError(error);

						throw error;
					});
			}

			search(filters: Filters): Promise<?AlgoliaResponse> {
				const allFilters = { ...options.defaultFilters, ...filters };
				if (!this.hasParameters(allFilters)) {
					const nextState = {
						pageCount: null,
						pagesLoaded: null,
						resultCount: null,
						results: null,
					};

					this.setState(nextState);

					return Promise.resolve(null);
				}

				this.setState({ loading: true });
				this._searchRequest = cancelablePromise(
					search(this.props.searchKey, buildAlgoliaQuery(allFilters)),
				);
				return this._searchRequest
					.then((response: AlgoliaResponse) => {
						const nextState = {
							loading: false,
							pageCount: response.nbPages,
							pagesLoaded: response.page + 1,
							resultCount: response.nbHits,
							results: response.hits,
						};

						delete this._searchRequest;
						this.props.searchSuccess(response);

						this.setState(nextState);

						return response;
					})
					.catch((error) => {
						this.setState({ loading: false });
						reportError(error);

						throw error;
					});
			}

			handleChange = (filters: Filters): void => {
				this.cancelSearch();
				if (options.history) {
					this.props.setSearchParams(
						new URLSearchParams(
							querystring.stringify(serialize(filters)),
						),
						{ replace: true },
					);
				}
				this.setState({ filters });
				this.search(filters);
			};

			handleScroll = (): void => {
				this.page();
			};

			render() {
				const props: Config = omit(this.props, [
					// From WrapWithSearchParams
					'searchParams',
					'setSearchParams',

					// From mapStateToProps
					'merged',
					'robots',
					'searchKey',

					// From mapDispatchToProps
					'dispatch',
					'searchSuccess',
				]);

				return (
					<ComposedComponent {...props} {...this.getProperties()} />
				);
			}
		}

		return connect(
			mapStateToProps,
			mapDispatchToProps,
		)(WrapWithSearchParams);
	};
};
