import React from 'react';
import { useRouteError } from 'react-router-dom-pinned-version-6';
import styled from 'styled-components';

import Header from '../components/header';
import View from '../components/view';
import EmptyStatePlaceholder from '../theme/components/empty-state-placeholder';
import { ResponseError } from '../utils/api';

const Main = styled.main`
	display: grid;
	flex: 1 1 0;
	grid-template:
		'explorer sidebar' 1fr
		/ 1fr auto;
	overflow: hidden;
`;
const ErrorContainer = styled.div`
	padding: 24px;
`;

const NotActiveContainer = () => (
	<ErrorContainer>
		<EmptyStatePlaceholder>Data room is not active.</EmptyStatePlaceholder>
	</ErrorContainer>
);

const FailedToLoadContainer = () => (
	<ErrorContainer>
		<EmptyStatePlaceholder>Failed to load data room.</EmptyStatePlaceholder>
	</ErrorContainer>
);

const DoesNotExistContainer = () => (
	<ErrorContainer>
		<EmptyStatePlaceholder>Data room does not exist.</EmptyStatePlaceholder>
	</ErrorContainer>
);

export function LoaderErrorFallback() {
	const error = useRouteError();

	if (
		error instanceof ResponseError
		&& (error.response.status === 403 || error.response.status === 404)
	) {
		return (
			<View>
				<Header />
				<Main>
					<DoesNotExistContainer />
				</Main>
			</View>
		);
	}

	return (
		<View>
			<Header />
			<Main>
				<FailedToLoadContainer />
			</Main>
		</View>
	);
}

export default function ErrorFallback({ error }: { error: Error | unknown }) {
	if (
		error instanceof ResponseError
		&& (error.response.status === 403 || error.response.status === 404)
	) {
		return <NotActiveContainer />;
	}

	return <FailedToLoadContainer />;
}
